import React, { useEffect , useState } from 'react';
import './About.css';
import BackgroundAboutus from '../../Assets/About/BackgroundAboutus.png'
import AboutUsimages from '../../Assets/About/AboutUsimages.png'
import Faculty from '../../Assets/About/Faculty.png';
import Rahul from '../../Assets/About/Trainers/Rahul.jpg';
import Anil from '../../Assets/About/Trainers/Anil.jpg';
import Alisha from '../../Assets/About/Trainers/Alisha.jpg';
import Saloni from '../../Assets/About/Trainers/Saloni.jpg';
import Sanhita from '../../Assets/About/Trainers/Sanhita.jpg';
import Vaidehi from '../../Assets/About/Trainers/Vaidehi.jpg';
import JD from '../../Assets/About/Trainers/JD.jpg';
import SJ from '../../Assets/About/Trainers/SJ.jpg';
import Sonu from '../../Assets/About/Trainers/Sonu.jpg';
import Chaitrali from '../../Assets/About/Trainers/Chaitrali.jpg';
import AR from '../../Assets/About/Trainers/AR.jpg';
import Background2aboutus from '../../Assets/About/Background2aboutus.png'
import Highlights from '../../Assets/About/Highlights.png';
import { Link } from 'react-router-dom';
import ReactReadMoreReadLess from "react-read-more-read-less";
import * as Icon from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AOS from "aos";
import "aos/dist/aos.css";

const longText =
  "Various universities and colleges around the world use varied methods and criteria to judge and grade the performance of a student. These marking systems vary even among the universities in one country, thus making it difficult to place all the students entering one level from heterogeneous education systems. Entrance exams help bring all the students at par to understand the student’s capability in Mathematical and Verbal Reasoning. ";


function About() {
    useEffect(() => {
        AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
    }, []);
    const [Show1, set1Show] = useState(false);
    const [Show2, set2Show] = useState(false);
    const [Show3, set3Show] = useState(false);
    const [Show11, set11Show] = useState(false);
    const [Show12, set12Show] = useState(false);
    const [Show13, set13Show] = useState(false);
    const [Show4, set4Show] = useState(false);
    const [Show5, set5Show] = useState(false);
    const [Show6, set6Show] = useState(false);
    const [Show14, set14Show] = useState(false);
    const [Show15, set15Show] = useState(false);
    const [Show16, set16Show] = useState(false);
    
    return (
        <div>
            <section id="hero" className="hero d-flex align-items-center heroAboutus" style={{
                backgroundImage: `url(${BackgroundAboutus}) `
            }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center col-margin">
                            <h1 data-aos="fade-up">WHICH EXAM SHOULD I APPEAR FOR????</h1>
                            <h5 data-aos="fade-up" data-aos-delay="400" className="txtColorwhite">
                              <ReactReadMoreReadLess
                                charLimit={200}
                                readMoreText={"Read more ▼"}
                                readLessText={"Read less ▲"}
                                readMoreClassName="read-more-less--more"
                                readLessClassName="read-more-less--less">
                                {longText}
                              </ReactReadMoreReadLess>
                            </h5>
                            <div data-aos="fade-up" data-aos-delay="600">
                                <div className="text-lg-start">
                                    <Link to="/login" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                                        <span>Get Started</span>
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
                            <img src={AboutUsimages} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <main id="main" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 d-flex flex-column justify-content-center">
                                
                            <header className="section-header marginTop" data-aos="fade-up">
                                <p>About Us !!!</p>
                            </header>
                            <p data-aos="fade-up" data-aos-delay="400">
                                We, at Imperial, are a hopping pack of hustling people who have coalesced to rally behind our students to empower and equip them to seize their dreams of studying abroad.We don't just sermonize; we ensure we chase your dreams till the very completion of this pursuit. 
                            </p>
                            <p data-aos="fade-up" data-aos-delay="400" >
                                We have painstakingly generated a tensile student network of 10K+ worldwide within just a few years of sheer assiduity and dedication. From a dwarfish team of just 4 starting in a teeny-weeny office in Mumbai, to a devoted, ever-efflorescing team across 5 offices in Mumbai and Pune, our odyssey has been nothing less than imperial!                             
                            </p>
                            <p data-aos="fade-up" data-aos-delay="400" >
                             We strongly believe that you get only one chance to make that maiden impression, and we work towards your applications with this thought process. We strive to deliver quality and honesty, and for us, success is measured by the number of positive responses we get and not by the bottom line.
                            </p>
                            <p data-aos="fade-up" data-aos-delay="400" >
                              We also completely disbelieve in the theory of 'settling' for something and hence tenaciously hunt for ways to make every student's journey at Imperial smooth-sailing. With our charmed circle coruscating with passion and experience, and many of them having studied and worked abroad, we thrive on making sure you land up and then fly and soar in your fittest firmament.
                            </p>
                            <p data-aos="fade-up" data-aos-delay="400" >
                            We are spurred by a genuine commitment towards the success of each of our students, and our existence and growth pivot around their growth. Everyday, we roll up our sleeves to cinch that you get the most hassle-free and personalized experience at such a cardinal decision-making juncture! We make your journey as sublime as your destination.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row dspBlock" >
                        <div className="col-lg-12 d-flex flex-column justify-content-center">
                                
                            <header className="section-header" data-aos="fade-up">
                                <p>Training at Imperial !!!</p>
                            </header>
                            <div className="col-lg-12 textAlignCenter" style={{ display :'contents'}}>
                                <img src={Highlights} className="img-fluid" alt="" />
                            </div>
                            <p></p>
                            <p></p>
                            <p data-aos="fade-up" data-aos-delay="400" >
                                Various universities and {'\n'} colleges around the world use varied methods and criteria to judge and grade the performance of a student. These marking systems vary even among the universities in one country, thus making it difficult to place all the students entering one level from heterogeneous education systems. Entrance exams help bring all the students at par to understand the student’s capability in Mathematical and Verbal Reasoning.           
                            </p>
                            <p data-aos="fade-up" data-aos-delay="400" >
                              Thus, SAT/ACT for Bachelor’s programs, GRE for technical Master’s programs, and GMAT for Postgraduate Management programs are mandatory in almost all the top universities. These entrance exams generally serve as the first step in many university selection processes. 
                            </p>
                            <p data-aos="fade-up" data-aos-delay="400" >
                              Since the education that the student is looking to pursue is in a country where English is the native language or at least the language of instruction, students are expected to provide proof of English proficiency in Reading, Writing, Listening and Speaking English through either IELTS, TOEFL, or PTE.For studying in the USA/Germany, TOEFL is preferred, and in UK/Australia/New Zealand/Canada, an IELTS/PTE score is preferred.Training at Imperial Students can join Imperial for training as well as counseling. We have a comprehensive and an exhaustive training program. Our Trainers are best in the industry - graduates and post graduates from India and Abroad having themselves done their due drill. We have convenient Weekday and Weekend batches to suit the students’ needs and even a vacation batch for when you are on leave in between semesters.
                        
                            </p>

                            
                        </div>
                    </div>
                </div>
                <section id="faculties" className="features">
                    <div className="container aos-init aos-animate " data-aos="fade-up">
                        <header className="section-header">
                            <p>Profiles of our Trainers (GRE/GMAT/SAT)</p>
                            <h2>Would you like to get acquainted with your mentors? Here you go!</h2>
                        </header>
                        <div className="row">
                            <div className="col-lg-6">
                                <img src={Faculty} className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
                                <div className="row align-self-center gy-4" style={{     maxHeight: '460px' , overflow: 'auto' }}>
                                    <div className="col-md-12 aos-init aos-animate marginTB" data-aos="zoom-out" data-aos-delay="200">
                                        <div className="feature-box d-flex align-items-center">
                                            <div className="col-md-2 ">
                                                <img src={Rahul} className="img-fluid" alt="" />
                                            </div>
                                            <div className="col-md-10 leftPadding" style={{ padding : '0px 15px'}}>
                                                <h3>Mr. Rahul Bhat</h3>
                                                <p className="marginT5">Head Quants Trainer /Senior Counselor</p>
                                                <button className="btn btn-primary btn-sm" onClick={() => set1Show(true)}>View More</button>
                                                <Modal show={Show1} onHide={() => set1Show(false)}>
                                                    <Modal.Header >
                                                      <Modal.Title><b>Rahul Bhat</b> :- Head Quants Trainer /Senior Counselor</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        An eccentric engineer with over 8 years of industry experience in Mechanical Engineering, Rahul has been associated with MNCs like Larsen & Toubro Ltd and Whirlpool of India Ltd as well as with startups in the energy sector. He completed his M.Tech. in Energy Systems Engineering from Indian Institute of Technology, Bombay in 2012 and Bachelor’s in Mechanical Engineering from Sardar Patel College of Engineering, Mumbai University in 2007.
                                                        His academic background makes it facile for him to guide and counsel students for higher studies overseas. Given his passion for Mathematics, Rahul is an Ace Quant Trainer who will help you learn tricks and crack the quant section with ease for GRE, SAT, and GMAT. With over three years of experience in overseas counseling, he guides students for the entire process with ease. He has over eight years of industry experience as well which comes handy while counselling students for their career plans.
                                                        He loves to play chess, badminton, and football and goes on regular treks besides running marathons.

                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                      <Button variant="secondary" onClick={() => set1Show(false)}>
                                                        Close
                                                      </Button>
                                                    </Modal.Footer>
                                                </Modal>                                             
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 aos-init aos-animate marginTB" data-aos="zoom-out" data-aos-delay="200">
                                        <div className="feature-box d-flex align-items-center">
                                            <div className="col-md-2 ">
                                                <img src={Anil} className="img-fluid" alt="" />
                                            </div>
                                            <div className="col-md-10 leftPadding" style={{ padding : '0px 15px'}}>

                                                <h3>Mr. Anil Kulkarni</h3>
                                                <p className="marginT5">Head Verbal Trainer /Counselor</p>
                                                <button className="btn btn-primary btn-sm" onClick={() => set2Show(true)}>View More</button>
                                                <Modal show={Show2} onHide={() => set2Show(false)}>
                                                    <Modal.Header >
                                                      <Modal.Title><b>Mr. Anil Kulkarni</b> :- Head Verbal Trainer /Counselor</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>Possessing both Engineering and Humanities background, he is a dynamic and passionate Senior Teacher/Trainer. He comes with a varied work experience of 25+ years of mentoring multitudes of students across myriad disciplines. Having taught/trained in many reputed companies, he has shepherded a slew of students through their journeys in achieving the dream scores of 750 in GMAT, 336 in GRE, 8.5 in IELTS, and so on. He is quite approachable and inheres the knack of making difficult things easy for you!
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                      <Button variant="secondary" onClick={() => set2Show(false)}>
                                                        Close
                                                      </Button>
                                                    </Modal.Footer>
                                                </Modal> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 aos-init aos-animate marginTB" data-aos="zoom-out" data-aos-delay="200">
                                        <div className="feature-box d-flex align-items-center">
                                            <div className="col-md-2 ">
                                                <img src={Alisha} className="img-fluid" alt="" />
                                            </div>
                                            <div className="col-md-10 leftPadding" style={{ padding : '0px 15px'}}>

                                                <h3>Ms. Alisha Chhabra</h3>
                                                <p className="marginT5">Verbal Trainer /Counselor</p>
                                                <button className="btn btn-primary btn-sm" onClick={() => set3Show(true)}>View More</button>
                                                <Modal show={Show3} onHide={() => set3Show(false)}>
                                                    <Modal.Header >
                                                      <Modal.Title><b>Ms. Alisha Chhabra</b> :- Verbal Trainer /Counselor</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>Wordbook's all-out aficionada: BFF! Having sailed English Honours from DU, Alisha went on to pursue MBA(HRD) from Delhi School of Economics. She has invariably cherished twirling round English Language and has been teaching it with all its variegated nuances for years. And writing for her is her very vocation that she gets to exercise while penning your creative pieces down or simply editing or reviewing them.</Modal.Body>
                                                    <Modal.Footer>
                                                      <Button variant="secondary" onClick={() => set3Show(false)}>
                                                        Close
                                                      </Button>
                                                    </Modal.Footer>
                                                </Modal> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 aos-init aos-animate marginTB" data-aos="zoom-out" data-aos-delay="200">
                                        <div className="feature-box d-flex align-items-center">
                                            <div className="col-md-2 ">
                                                <img src={AR} className="img-fluid" alt="" />
                                            </div>
                                            <div className="col-md-10 leftPadding" style={{ padding : '0px 15px'}}>
                                                <h3>Mr. Ankur Rupani</h3>
                                                <p className="marginT5">Quants and Verbal Trainer</p>
                                                <button className="btn btn-primary btn-sm" onClick={() => set11Show(true)}>View More</button>
                                                <Modal show={Show11} onHide={() => set1Show(false)}>
                                                    <Modal.Header >
                                                      <Modal.Title><b>Ankur Rupani</b> :- Quants and Verbal Trainer</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        Ankur has been training students for GRE, SAT, GMAT and other competitive exams for more than 12 years and has successfully taught and counseled over 5000 students. He is an alumnus of University of Maryland College Park, USA and Indian Institute of Technology, Varanasi. Having completed his Master’s in USA on a full scholarship, he is an expert in guiding students for assistantships and scholarships at Universities abroad. Other than an Engineering background and Master's degree, he has another degree in Spirituality and Education from University of Naropa, USA. 
                                                        With an interest in positive psychology, spirituality and personal development, Ankur is also a motivational speaker and life coach.

                                                     </Modal.Body>
                                                    <Modal.Footer>
                                                      <Button variant="secondary" onClick={() => set11Show(false)}>
                                                        Close
                                                      </Button>
                                                    </Modal.Footer>
                                                </Modal>                                             
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 aos-init aos-animate marginTB" data-aos="zoom-out" data-aos-delay="200">
                                        <div className="feature-box d-flex align-items-center">
                                            <div className="col-md-2 ">
                                                <img src={JD} className="img-fluid" alt="" />
                                            </div>
                                            <div className="col-md-10 leftPadding" style={{ padding : '0px 15px'}}>

                                                <h3>Mr. JOYDEEP DUTTA</h3>
                                                <p className="marginT5">Quants and Verbal Trainer</p>
                                                <button className="btn btn-primary btn-sm" onClick={() => set12Show(true)}>View More</button>
                                                <Modal show={Show12} onHide={() => set2Show(false)}>
                                                    <Modal.Header >
                                                      <Modal.Title><b>Mr. JOYDEEP DUTTA</b> :- Quants and Verbal Trainer</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>Just like his name suggests, Joydeep or JD as he fondly referred to by his students and colleagues, is a cheerful personality to be around. He brings in more than 20 years of experience in the field of academia, where majority of it was spent guiding and teaching students.
                                                    JD is an alumnus and former faculty of Indian Institute of Technology, Kharagpur, and now serves as a Professor at Pillai HOC College of Architecture. He balances this responsibility well with his other role of a trainer and guide for GRE, SAT, GMAT at Imperial. He also holds a Masters in Architecture & Urban Planning from University of Illinois at Urbana-Champaign.

                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                      <Button variant="secondary" onClick={() => set12Show(false)}>
                                                        Close
                                                      </Button>
                                                    </Modal.Footer>
                                                </Modal> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 aos-init aos-animate marginTB" data-aos="zoom-out" data-aos-delay="200">
                                        <div className="feature-box d-flex align-items-center">
                                            <div className="col-md-2 ">
                                                <img src={SJ} className="img-fluid" alt="" />
                                            </div>
                                            <div className="col-md-10 leftPadding" style={{ padding : '0px 15px'}}>

                                                <h3>Mr. Sujay Joshi</h3>
                                                <p className="marginT5">Quants Trainer</p>
                                                <button className="btn btn-primary btn-sm" onClick={() => set13Show(true)}>View More</button>
                                                <Modal show={Show13} onHide={() => set3Show(false)}>
                                                    <Modal.Header >
                                                      <Modal.Title><b>Mr. Sujay Joshi</b> :- Quants Trainer</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>Sujay started his career as a quantitative trainer in 2008. With total 12 years of experience of the education sector, he has spent 9 years in study-abroad training and consultancy. He is well versed with GRE and GMAT and has achieved a perfect score in quantitative section of both the exams. Sujay also attended a graduate school at Southern Illinois University, Carbondale, USA, in 2017 as a full-time research scholar. Apart from work, he has a special interest in International Politics and Vedic astrology.
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                      <Button variant="secondary" onClick={() => set13Show(false)}>
                                                        Close
                                                      </Button>
                                                    </Modal.Footer>
                                                </Modal> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="faculties" className="features">
                    <div className="container aos-init aos-animate" data-aos="fade-up">
                        <header className="section-header">
                            <p>Profiles of our Trainers (IELTS/TOEFL)</p>
                            <h2>Let us apprise you of the hands guiding you on this journey!</h2>
                        </header>
                        <div className="row">
                            <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
                                <div className="row align-self-center gy-4" style={{     maxHeight: '460px' , overflow: 'auto' }}>
                                    
                                    <div className="col-md-12 aos-init aos-animate marginTB" data-aos="zoom-out" data-aos-delay="200">
                                        <div className="feature-box d-flex align-items-center">
                                            <div className="col-md-2 ">
                                                <img src={Saloni} className="img-fluid" alt="" />
                                            </div>
                                            <div className="col-md-10 leftPadding" style={{ padding : '0px 15px'}}>

                                                <h3>Ms. Saloni Magre</h3>
                                                <p className="marginT5">Trainer and Senior Counselor</p>
                                                <button className="btn btn-primary btn-sm" onClick={() => set4Show(true)}>View More</button>
                                                <Modal show={Show4} onHide={() => set4Show(false)}>
                                                    <Modal.Header >
                                                      <Modal.Title><b>Ms. Saloni Magre</b> :- Trainer and Senior Counselor</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>A Hard-Working and Motivated Trainer & a Senior Counsellor with a Postgraduate Degree in Marketing & Human Resource Management, Saloni is a Gold Medalist in English. She has diligently acquired extensive knowledge in the field of  IELTS & TOEFL Training and has been painstakingly training students for the last 5+ years. Her contribution has been immense through Guidance & Counselling of  Students to a multitude of countries like USA/UK/IRELAND/AUSTRALIA/NZ etc. Preeminent for her perennial smile, she is unconditionally approachable.
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                      <Button variant="secondary" onClick={() => set4Show(false)}>
                                                        Close
                                                      </Button>
                                                    </Modal.Footer>
                                                </Modal> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 aos-init aos-animate marginTB" data-aos="zoom-out" data-aos-delay="200">
                                        <div className="feature-box d-flex align-items-center">
                                            <div className="col-md-2 ">
                                                <img src={Vaidehi} className="img-fluid" alt="" />
                                            </div>
                                            <div className="col-md-10 leftPadding" style={{ padding : '0px 15px'}}>

                                                <h3>Ms. Vaidehi Purohit</h3>
                                                <p className="marginT5">Trainer and Senior Counselor</p>
                                                <button className="btn btn-primary btn-sm" onClick={() => set5Show(true)}>View More</button>
                                                <Modal show={Show5} onHide={() => set5Show(false)}>
                                                    <Modal.Header >
                                                      <Modal.Title><b>Ms. Vaidehi Purohit</b> :- Trainer and Senior Counselor</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>An alumna of Unitec Institute of Technology, Auckland, New Zealand, with over 5 years of experience in overseas counseling, Vaidehi is a Trainer for IELTS, TOEFL, and PTE as well as a Senior Counselor who is an expert at guiding students for overseas education in countries like Australia, New Zealand, UK, and Ireland. She has graduated with a Bachelor's in Psychology from Wilson College, Mumbai, and additionally holds a Master's in Social Work. She also has considerable exposure as an HR & Admin for over 3 years and helps students zero in on their most fitting career option and University.
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                      <Button variant="secondary" onClick={() => set5Show(false)}>
                                                        Close
                                                      </Button>
                                                    </Modal.Footer>
                                                </Modal> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 aos-init aos-animate marginTB" data-aos="zoom-out" data-aos-delay="200">
                                        <div className="feature-box d-flex align-items-center">
                                            <div className="col-md-2 ">
                                                <img src={Sanhita} className="img-fluid" alt="" />
                                            </div>
                                            <div className="col-md-10 leftPadding" style={{ padding : '0px 15px'}}>

                                                <h3>Ms. Sanhita Bhattacharya </h3>
                                                <p className="marginT5">Trainer and Counselor</p>
                                                <button className="btn btn-primary btn-sm" onClick={() => set6Show(true)}>View More</button>
                                                <Modal show={Show6} onHide={() => set6Show(false)}>
                                                    <Modal.Header >
                                                      <Modal.Title><b>Ms. Sanhita Bhattacharya</b> :- Trainer and Counselor</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>Armed with a Master’s in Human Resources, Sanhita has always been passionate about teaching with an affinity towards the English Language. Sanhita's ambition of being a part of the education field stems from her desire to help students in achieving their dreams, and this desire has manifested itself through the actualization of the dreams of many of our students. Unremittingly sanguine and unrelentingly available, she is a go-getter at Imperial.
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                      <Button variant="secondary" onClick={() => set6Show(false)}>
                                                        Close
                                                      </Button>
                                                    </Modal.Footer>
                                                </Modal> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 aos-init aos-animate marginTB" data-aos="zoom-out" data-aos-delay="200">
                                        <div className="feature-box d-flex align-items-center">
                                            <div className="col-md-2 ">
                                                <img src={Sonu} className="img-fluid" alt="" />
                                            </div>
                                            <div className="col-md-10 leftPadding" style={{ padding : '0px 15px'}}>

                                                <h3>Ms. Sonu Vohra</h3>
                                                <p className="marginT5">Lead Trainer - For the English Proficiency</p>
                                                <button className="btn btn-primary btn-sm" onClick={() => set14Show(true)}>View More</button>
                                                <Modal show={Show14} onHide={() => set4Show(false)}>
                                                    <Modal.Header >
                                                      <Modal.Title><b>Ms. Sonu Vohra</b> :- Lead Trainer for the English Proficiency</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>As a part of the learning team , I have been training students for the past 8 years. Having completed my Bachelor's of Commerce from Kota University, I went ahead to pursue my Master's degree in Finance from TMV,  Pune. 
                                                    I have a considerable experience in the field of academia where majority of it was spent on facilitating  the process of teaching for students.
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                      <Button variant="secondary" onClick={() => set14Show(false)}>
                                                        Close
                                                      </Button>
                                                    </Modal.Footer>
                                                </Modal> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 aos-init aos-animate marginTB" data-aos="zoom-out" data-aos-delay="200">
                                        <div className="feature-box d-flex align-items-center">
                                            <div className="col-md-2 ">
                                                <img src={Vaidehi} className="img-fluid" alt="" />
                                            </div>
                                            <div className="col-md-10 leftPadding" style={{ padding : '0px 15px'}}>

                                                <h3>Ms. Michelle</h3>
                                                <p className="marginT5">Trainer and Senior Counselor</p>
                                                <button className="btn btn-primary btn-sm" onClick={() => set15Show(true)}>View More</button>
                                                <Modal show={Show15} onHide={() => set5Show(false)}>
                                                    <Modal.Header >
                                                      <Modal.Title><b>Ms. Vaidehi Purohit</b> :- Trainer and Senior Counselor</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>An alumna of Unitec Institute of Technology, Auckland, New Zealand, with over 5 years of experience in overseas counseling, Vaidehi is a Trainer for IELTS, TOEFL, and PTE as well as a Senior Counselor who is an expert at guiding students for overseas education in countries like Australia, New Zealand, UK, and Ireland. She has graduated with a Bachelor's in Psychology from Wilson College, Mumbai, and additionally holds a Master's in Social Work. She also has considerable exposure as an HR & Admin for over 3 years and helps students zero in on their most fitting career option and University.
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                      <Button variant="secondary" onClick={() => set15Show(false)}>
                                                        Close
                                                      </Button>
                                                    </Modal.Footer>
                                                </Modal> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 aos-init aos-animate marginTB" data-aos="zoom-out" data-aos-delay="200">
                                        <div className="feature-box d-flex align-items-center">
                                            <div className="col-md-2 ">
                                                <img src={Chaitrali} className="img-fluid" alt="" />
                                            </div>
                                            <div className="col-md-10 leftPadding" style={{ padding : '0px 15px'}}>

                                                <h3>Ms. Chaitrali Deshpande</h3>
                                                <p className="marginT5">Trainer and Counselor</p>
                                                <button className="btn btn-primary btn-sm" onClick={() => set16Show(true)}>View More</button>
                                                <Modal show={Show16} onHide={() => set6Show(false)}>
                                                    <Modal.Header >
                                                      <Modal.Title><b>Ms. Chaitrali Deshpande</b> :- Trainer and Counselor</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>My responsibilities are to provide Letters of Recommendations. Help every student with their applications, and Training of IELTS and TOEFL for Speaking. I love to help people around and give my best knowledge to them. 
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                      <Button variant="secondary" onClick={() => set16Show(false)}>
                                                        Close
                                                      </Button>
                                                    </Modal.Footer>
                                                </Modal> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img src={Faculty} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section id="highlihts" className="features heroFaculties" style={{ backgroundImage: `url(${Background2aboutus})` }}>
                    <div className="container aos-init aos-animate" data-aos="fade-up">
                        <header className="section-header">
                            <p>OUR FORMIDABLE FEATURES</p>
                            <h2>A slew of services and a raft of features in tandem with our cardinal value render Imperial imperially inimitable in an otherwise saturated firmament.</h2>
                            
                        </header>
                        <div className="row">
                            
                            <div className="col-lg-12 mt-10 mt-lg-0 d-flex">
                                <div className="row align-self-center gy-4">
                                    <div className="col-md-6 marginTB" data-aos="zoom-out" data-aos-delay="200">
                                        <div className="feature-box d-flex align-items-center">
                                            
                                            <Icon.FaCheck color="royalblue"  size={20} className="marginLR" />
                                            <h3>Personalized Learning</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6 marginTB" data-aos="zoom-out" data-aos-delay="300">
                                        <div className="feature-box d-flex align-items-center">
                                            <Icon.FaCheck color="royalblue"  size={20} className="marginLR" />
                                            <h3>Individual Attention</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6 marginTB" data-aos="zoom-out" data-aos-delay="400">
                                        <div className="feature-box d-flex align-items-center">
                                            <Icon.FaCheck color="royalblue"  size={20} className="marginLR" />
                                            <h3>Concept Clarity through Visuals</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6 marginTB" data-aos="zoom-out" data-aos-delay="500">
                                        <div className="feature-box d-flex align-items-center">
                                            <Icon.FaCheck color="royalblue"  size={20} className="marginLR" />
                                            <h3>Know that your individual strengths and weaknesses</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6 marginTB" data-aos="zoom-out" data-aos-delay="600">
                                        <div className="feature-box d-flex align-items-center">
                                            <Icon.FaCheck color="royalblue"  size={20} className="marginLR" />
                                            <h3>Your profile will be heeded and ministered</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6 marginTB" data-aos="zoom-out" data-aos-delay="700">
                                        <div className="feature-box d-flex align-items-center">
                                            <Icon.FaCheck color="royalblue"  size={20} className="marginLR" />
                                            <h3>We attempt to colour and polish all learning</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* <section id="testimonials" className="testimonials heroFooter ">
                    <div className="container testimonialBox" data-aos="fade-up" >
                        <header className="section-header" >
                            <h2>Testimonials</h2>
                            <p>What they are saying about us</p>
                        </header>
                        <div
                            className="testimonials-slider swiper-container"
                            data-aos="fade-up"
                            data-aos-delay="200"
                        >
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="testimonial-item">
                                        <div className="stars">
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                        </div>
                                        <p>
                                            Proin iaculis purus consequat sem cure digni
                                            ssim donec porttitora entum suscipit
                                            rhoncus. Accusantium quam, ultricies eget
                                            id, aliquam eget nibh et. Maecen aliquam,
                                            risus at semper.
                                        </p>
                                        <div className="profile mt-auto">
                                            <img
                                                src="/assets/img/testimonials/avatar-female.png"
                                                className="testimonial-img"
                                                alt=""
                                            />
                                            <h3>Name 1</h3>
                                            <h4>ABCD College</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testimonial-item">
                                        <div className="stars">
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                        </div>
                                        <p>
                                            Export tempor illum tamen malis malis eram
                                            quae irure esse labore quem cillum quid
                                            cillum eram malis quorum velit fore eram
                                            velit sunt aliqua noster fugiat irure amet
                                            legam anim culpa.
                                        </p>
                                        <div className="profile mt-auto">
                                            <img
                                                src="/assets/img/testimonials/avatar-male.png"
                                                className="testimonial-img"
                                                alt=""
                                            />
                                            <h3>Name 2</h3>
                                            <h4>PQRS College</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testimonial-item">
                                        <div className="stars">
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                        </div>
                                        <p>
                                            Enim nisi quem export duis labore cillum
                                            quae magna enim sint quorum nulla quem
                                            veniam duis minim tempor labore quem eram
                                            duis noster aute amet eram fore quis sint
                                            minim.
                                        </p>
                                        <div className="profile mt-auto">
                                            <img
                                                src="/assets/img/testimonials/avatar-female.png"
                                                className="testimonial-img"
                                                alt=""
                                            />
                                            <h3>Name 3</h3>
                                            <h4>LL College</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testimonial-item">
                                        <div className="stars">
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                        </div>
                                        <p>
                                            Fugiat enim eram quae cillum dolore dolor
                                            amet nulla culpa multos export minim fugiat
                                            minim velit minim dolor enim duis veniam
                                            ipsum anim magna sunt elit fore quem dolore
                                            labore illum veniam.
                                        </p>
                                        <div className="profile mt-auto">
                                            <img
                                                src="/assets/img/testimonials/avatar-male.png"
                                                className="testimonial-img"
                                                alt=""
                                            />
                                            <h3>aa</h3>
                                            <h4>aaaa ccdxx</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testimonial-item">
                                        <div className="stars">
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                            <i className="bi bi-star-fill"></i>
                                        </div>
                                        <p>
                                            Quis quorum aliqua sint quem legam fore sunt
                                            eram irure aliqua veniam tempor noster
                                            veniam enim culpa labore duis sunt culpa
                                            nulla illum cillum fugiat legam esse veniam
                                            culpa fore nisi cillum quid.
                                        </p>
                                        <div className="profile mt-auto">
                                            <img
                                                src="/assets/img/testimonials/avatar-female.png"
                                                className="testimonial-img"
                                                alt=""
                                            />
                                            <h3>John</h3>
                                            <h4>N.M College</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                </section> */}

            </main>
        </div>
    )
}

export default About;