import React, { useEffect } from "react";
import { useStopwatch } from "react-timer-hook";

function StopWatch({ questionCount, type = true, resetTime, ...props }) {
  const { seconds, minutes, hours, reset } = useStopwatch({ autoStart: true });

  useEffect(() => {
    reset();
  }, [questionCount, resetTime]);

  useEffect(() => {
    if (!type && minutes >= questionCount) props.timeLimitSubmit();
    var _data = { minutes: minutes, seconds: seconds, hours: hours };
    localStorage.setItem("time", JSON.stringify(_data));

    return () => localStorage.removeItem("time");
  }, [minutes, seconds, hours]);

  return (
    <div>
      <div style={type ? {} : { fontSize: 18, fontWeight: "bold" }}>
        <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
      </div>
      {type && <p>Time Elapsed</p>}
    </div>
  );
}

export function StopWatch2({
  questionCount,
  type = true,
  testChange,
  ...props
}) {
  const { seconds, minutes, hours, reset } = useStopwatch({ autoStart: true });

  useEffect(() => {
    reset();
  }, [questionCount, testChange]);

  useEffect(() => {
    if (!type && minutes >= questionCount) props.timeLimitSubmit();
    var _data = { minutes: minutes, seconds: seconds, hours: hours };
    localStorage.setItem("time2", JSON.stringify(_data));

    return () => localStorage.removeItem("time2");
  }, [minutes, seconds, hours]);

  return (
    <div>
      <div style={type ? {} : { fontSize: 18, fontWeight: "bold" }}>
        <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
      </div>
      {type && <p>Time Elapsed</p>}
    </div>
  );
}

export default StopWatch;
