import React, { useEffect, useState } from "react";
import { apiCallToken } from "../../Utils/Network.js";
import AccordianContainer from "../../Components/AccordianContainer/AccordianContainer";
import { video_list } from "../../Utils/ApiUrls";
import AccordianContainerNew from "../../Components/AccordianContainerNew/AccordianContainerNew.js";
function TestResult() {
  const [navData, setNavData] = useState([]);
  const [accordianData, setAccordianData] = useState();
  const [tabSelected, setTabSelected] = useState(0);
  const [isActive, setIsActive] = useState(0);
  const [accordianTitle, setAccordianTitle] = useState("");
  const [questionCountId, setQuestionCountId] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  // console.log(accordianTitle,'accordianTitle')
  const getData = () => {
    apiCallToken(`/student/result/all`)
      .then((response) => {
        // console.log(response.data.data.attempts_count, "response for question");
        setQuestionCountId(response.data.data.attempts_count);
        setNavData(response.data.data);
        var first_key = Object.keys(response.data.data)[0];
        var first_value = Object.keys(response.data.data[first_key])[0];
        setAccordianTitle(first_value);
        var second_key = response.data.data[first_key];
        var second_value = second_key[Object.keys(second_key)[0]];
        // console.log(second_key,'second_key')
        setAccordianData(second_value);
      })
      .catch((error) => {
        console.log(error, "error fof video");
      });
  };

  const checkClick = (idx, tabIdx, _data) => {
    setTabSelected(tabIdx);
    setIsActive(idx);
    setAccordianData(_data);
    var first_key = Object.keys(navData)[tabIdx];
    var second_key = Object.keys(navData[first_key])[idx];
    setAccordianTitle(second_key);
  };

  return (
    <div className="video-container-pc" style={{ marginTop: "100px" }}>
      {accordianTitle ? (
        <div className="video-container-pclf">
          <div className="video-sub-menu">
            <div className="left-nav-c">
              {Object.entries(navData).map(([key, value], idx) => {
                var active_list = tabSelected === idx ? true : false;
                return (
                  <ul
                    className="outer-ul-li-c"
                    style={{ listStyleType: "none" }}
                    key={idx}
                    // onClick={() => checkClick(idx, "selected", value)}
                  >
                    <li style={{ background: "#F0F0F0" }}>
                      <p className="ul-li-header-c">{key}</p>
                      {Object.entries(value).map(([key, value], idxi) => {
                        return (
                          <ul
                            className={`${
                              active_list && idxi === isActive
                                ? "inner-ul-li-c-active"
                                : "inner-ul-li-c"
                            }`}
                            onClick={() => checkClick(idxi, idx, value)}
                            style={{ listStyleType: "none" }}
                            key={idxi}
                          >
                            <li>{key}</li>
                          </ul>
                        );
                      })}
                    </li>
                  </ul>
                );
              })}
            </div>
          </div>
          <div className="video-list-item">
            <div className="right-nav-details">
              <p className="nav-details-h">
                <b>{accordianTitle}</b>
              </p>
              <AccordianContainerNew
                _data={accordianData}
                _title={accordianTitle}
                type="result2"
                q_id={questionCountId}
              />
            </div>
          </div>
        </div>
      ) : (
        <div style={{ margin: "40vh auto", textAlign: "center" }}>
          No Test Given.
        </div>
      )}
    </div>
  );
}

export default TestResult;
