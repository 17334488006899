import React from 'react';
import './Footer.css';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import LogoImg from '../../Assets/footer/logoWhite.png'

function Footer() {
    return (
        <footer id="footer" className="footer">

            {/* <div>
                <div className="footer-top">
                    <Container>
                        <Row className="gy-4">
                            <Col lg={4} md={12}>
                                <div className="footer-info">
                                    <a href="index.html" className="logo d-flex align-items-center">
                                        <img src={LogoImg} alt="" />
                                    </a>
                                    <p style={{textAlign:'left'}}>Imperial group is leader in Overseas education, Test Preparation , Recruitment, Lending and Trading. We have our presence in India, USA, UAE and Singapore.</p>
                                    <div className="social-links mt-3">
                                        <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
                                        <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
                                        <a href="#" className="instagram"><i className="bi bi-instagram bx bxl-instagram"></i></a>
                                        <a href="#" className="linkedin"><i className="bi bi-linkedin bx bxl-linkedin"></i></a>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={2}>
                                <div className="footer-links">
                                    <h4 style={{textAlign:'left'}}>Useful Links</h4>
                                    <ul>
                                        <li><i className="bi bi-chevron-right"></i> <a href="#">Home</a></li>
                                        <li><i className="bi bi-chevron-right"></i> <a href="/about">About us</a></li>
                                        <li><i className="bi bi-chevron-right"></i> <a href="/price">Fee Structure</a></li>
                                        <li><i className="bi bi-chevron-right"></i> <a href="/privacyPolicy">Privacy policy</a></li>
                                        <li><i className="bi bi-chevron-right"></i> <a href="/TermsCondition">Terms & Condition</a></li>
                                    </ul>
                                </div>
                            </Col>

                            <Col lg={3}>
                                <div className="footer-contact text-center text-md-start">
                                    <h4 style={{textAlign:'left'}}>Contact Us</h4>
                                    
                                        <p style={{textAlign:'left'}}>
                                            <b>US - Imperial Platforms Inc : </b> <br/>580 California St, Suite 1200, <br/>San Francisco, CA, US State, 94104 <br/>
                                            <strong>Phone:</strong> +1 347 328 8189 <br />
                                            <strong>Email:</strong> info@imperialonlinestudy.com<br />
                                        </p>
                                   
                                   
                                </div>
                            </Col>

                            <Col lg={3}>
                                <div className="footer-contact text-center text-md-start">
                                    <h4 style={{textAlign:'left'}}></h4>
                                    
                                        <p style={{textAlign:'left', marginTop:'38px'}}>
                                            <b>India - Imperial Platforms Pvt Ltd  :</b> <br/>Office no 32, 2nd Floor,<br/> Dheeraj Heritage, Santacruz (W),<br/> Mumbai 400054<br />
                                            <strong>Phone:</strong> +91 70213 59819<br />
                                            <strong>Email:</strong> info@mperialonlinestudy.com<br/>
                                        </p>
                                   
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div> */}
            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong><span> testprep.live </span></strong> | All Rights Reserved
                </div>
            </div>
        </footer>
    )
}

export default Footer;
