import React from "react";

function Loader() {
  return (
    <div
      style={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        backgroundColor: "#fbfbfb01",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        zIndex: 99,
      }}
    >
      <div class="spinner-border text-primary" role="status"></div>
    </div>
  );
}

export default Loader;
