export const login_url = '/student/login';
export const video_list = "/student/practise/data/video";
export const dashboard_list = "/student/dashboard";
export const reference_book_list = "/student/reference/Detail";
export const dashboard_listNew = "/student/dashboardnew";
export const sign_up = "/student/signup";
export const send_email_otp = "/student/send/emailotp";
export const verify_email_otp = "/student/verify/emailotp";
export const send_otp = "/student/send/otp";
export const verify_otp = "/student/verify/otp";
export const state_list = "/student/state/list";
export const city_list = (id) => `/student/city/list/${id}`;
export const clg_list = `/student/college/list`;
export const profile_update = "/student/profile/update";