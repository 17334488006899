import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";

function OtherComponents() {
  const { logout } = useContext(UserCredsContext);

  return (
    <div style={{ marginTop: "10px" }}>
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          Profile
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item>Profile</Dropdown.Item>
          <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default OtherComponents;
