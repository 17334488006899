import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { Dash } from "react-bootstrap-icons";
import { Link, withRouter } from "react-router-dom";
import { checkCountId } from "../../Utils/Utils";
import { apiCallToken } from "../../Utils/Network.js";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./AccordianContainerNew.css";

function AccordianContainerNew({
  _data,
  _title,
  type,
  user_type,
  q_id = null,
  ...props
}) {
  const [activeId, setActiveId] = useState("");
  const [iterateData, setIterateData] = useState({});
  const [activeVideoListId, setActiveVideoListId] = useState("");
  const [newVideoData, setVideoData] = useState({});
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    id: 1,
  });

  useEffect(() => {
    setActiveVideoListId("");
    setVideoData({});
  }, [_data]);

  const openVideoModalNew = (title, v_id, v_type) => {
    const data = {
      vid: v_id,
      type: v_type,
    };

    apiCallToken
      .post(`student/video/url`, data)
      .then((response) => {
        const newData = response.data.data;
        // console.log(
        //   newData,
        //   newData.video_name,
        //   newData.keyurl,
        //   "data in local"
        // );

        setShow(true);
        setModalData({
          ...modalData,
          title: newData.video_name,
          url: newData.keyurl,
        });
      })
      .catch((error) => {
        console.log(error, "error fof video");
        alert("Something went wrong");
      });
  };

  /*const openVideoModal = (title) => {

    setShow(true);
    setModalData({ ...modalData, title });

  };*/
  const getData = (VID) => {
    apiCallToken
      .get(`/student/practise/video/id/${VID}`)
      .then((response) => {
        // console.log(
        //   newData,
        //   newData.video_name,
        //   newData.keyurl,
        //   "data in local"
        // );
        //const newVideoData = response.data.data;/
        setVideoData(response.data.data);
        console.log(response.data.data, "response for test");

        //console.log(response.data.data.video_list, "response for test");
        // setShow(true);
        // setModalData({
        //   ...modalData,
        //   title: newData.video_name,
        //   url: newData.keyurl,
        // });
      })
      .catch((error) => {
        console.log(error, "error fof video");
        alert("Something went wrong");
      });
  };

  const showHideDiv = (ele) => {
    if (activeVideoListId === ele) {
      setActiveVideoListId("");
    } else {
      setVideoData({});
      setActiveVideoListId(ele);
      getData(ele);
    }
    return false;
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  function toggleActive(id) {
    var new_id = id.toString();

    // console.log(new_id, "id is here");
    if (activeId === new_id) {
      setActiveId(null);
    } else {
      setActiveId(new_id);
    }
  }

  useEffect(() => {
    // Object.keys(_data).length
    if (_data) {
      setIterateData(_data);
      if (!_title || _title !== "Video List") setActiveId("0");
    }
  }, [_data]);

  // console.log(q_id, activeId, "_data");
  // console.log(iterateData, _data, "Object");
  const getMyArray = () => {
    <React.Fragment>
      {Object.keys(iterateData).length !== 0 && (
        <Accordion activeKey={activeId} style={{ padding: "5px 5px" }}>
          {Object.entries(iterateData).map(([key, value], idx) => {
            var new_id = idx.toString();
            return (
              <Accordion.Item eventKey={new_id + ""}>
                <div
                  className={
                    activeId === new_id
                      ? "panel-wrap active-panel"
                      : "panel-wrap"
                  }
                >
                  <div className="panel-header">
                    <Accordion.Header
                      onClick={() => toggleActive(new_id)}
                      className="panel-toggle"
                      variant="link"
                      eventKey={new_id + ""}
                    >
                      <div className="accordian-head-c">
                        <div>
                          {idx + 1} {key}
                        </div>
                        <div>
                          {activeId == new_id && <Plus />}
                          {activeId != new_id && <Dash />}
                        </div>
                      </div>
                    </Accordion.Header>
                  </div>

                  <Accordion.Body
                    eventKey={new_id + ""}
                    style={{ backgroundColor: "white", borderRadius: "5px" }}
                  >
                    <div className="panel-body">
                      <table
                        className="table"
                        style={{ width: "100%", margin: "auto" }}
                      >
                        <tr>
                          <th>Sr. No.</th>
                          <th>Practise</th>
                          <th>Tutorial Documentary</th>
                          {type === "test" && <th>Total Attempts</th>}
                          {type === "result" && <th>Correct Answer</th>}
                        </tr>
                        {value.map((item, idx) => {
                          // var countId = checkCountId(item, q_id);
                          // console.log(item, "countId ss");
                          return (
                            <tr>
                              <td>{item.id}</td>
                              <td>{item.video_name || item.practice_name}</td>
                              <td>
                                {type === "video" && (
                                  <button
                                    className="btn btn-md btn-primary"
                                    onClick={() =>
                                      openVideoModalNew(
                                        item.practice_name,
                                        item.video_id || item.id,
                                        "NOHD"
                                      )
                                    }
                                  >
                                    Watch Video
                                  </button>
                                )}

                                {type === "test" &&
                                  item.attempts_count <= 2 && (
                                    <Link
                                      to={`test/${item.id}/${
                                        item.attempts_count + 1
                                      }`}
                                    >
                                      <button className="btn btn-md startTestBtn">
                                        Start Test (Attempt -{" "}
                                        {item.attempts_count + 1})
                                      </button>
                                    </Link>
                                  )}

                                {type === "test" &&
                                  item.attempts_count >= 3 && (
                                    <button className="btn btn-md btn-danger">
                                      Maximum Attempt Limit Over
                                    </button>
                                  )}
                              </td>

                              {type !== "video" && (
                                <td>{item.attempts_count}</td>
                              )}
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </Accordion.Body>
                </div>
              </Accordion.Item>
            );
          })}
        </Accordion>
      )}
    </React.Fragment>;
  };
  const getMyObject = () => {
    return (
      <React.Fragment>
        {Object.keys(iterateData).length !== 0 && (
          <Accordion activeKey={activeId} style={{ padding: "5px 5px" }}>
            {Object.entries(iterateData).map(([key, value], idx) => {
              var new_id = idx.toString();
              return (
                <Accordion.Item eventKey={new_id + ""}>
                  <div
                    className={
                      activeId === new_id
                        ? "panel-wrap active-panel"
                        : "panel-wrap"
                    }
                  >
                    <div className="panel-header">
                      <Accordion.Header
                        onClick={() => toggleActive(new_id)}
                        className="panel-toggle"
                        variant="link"
                        eventKey={new_id + ""}
                      >
                        <div className="accordian-head-c">
                          <div>
                            {idx + 1} {key}
                          </div>
                          <div>
                            {activeId == new_id && <Plus />}
                            {activeId != new_id && <Dash />}
                          </div>
                        </div>
                      </Accordion.Header>
                    </div>

                    <Accordion.Body
                      eventKey={new_id + ""}
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="panel-body">
                        <table
                          className="table"
                          style={{ width: "100%", margin: "auto" }}
                        >
                          <tr className="text-center">
                            {/*<th>Practise</th>*/}
                            {type === "result2" && <th>Attempt No</th>}
                            {type === "result2" && <th>Analysis</th>}
                            <th>
                              {type === "result2"
                                ? "View Result"
                                : "Tutorial Documentary Name"}
                            </th>
                            {type === "test" && <th>Start Test</th>}
                            {type === "test" && <th>Total Attempts</th>}
                            {type === "result" && <th>Correct Answer</th>}
                          </tr>
                          {value.map((item, idx) => {
                            // var countId = checkCountId(item, q_id);
                            // console.log(item, "countId ss");
                            return (
                              <tr className="text-center">
                                {/*<td>
                                  {type === "result2"
                                    ? item.practice_id
                                    : item.video_id || item.id}
                                </td>*/}
                                <td style={{ width: "30%" }}>
                                  {item.video_name || item.practice_name}
                                </td>

                                {type === "result2" && (
                                  <td>{item.attempt_number}</td>
                                )}
                                {type === "result2" && (
                                  <td>
                                    Correct - {item.correct_ans}
                                    <br />
                                    Wrong - {item.wrong_ans}
                                    <br />
                                    Blank - {item.blank_ans}
                                  </td>
                                )}

                                <td
                                  style={{
                                    width: type === "result2" ? "auto" : "40%",
                                  }}
                                >
                                  {type === "video" &&
                                    user_type === "UNPAID" &&
                                    item.view_status === "PAID" && (
                                      <button
                                        disabled
                                        className="btn btn-md btn-danger"
                                      >
                                        ACCESS TO PAID USER ONLY
                                      </button>
                                    )}

                                  {type === "video" &&
                                    (user_type === "PAID" ||
                                      item.view_status === "FREE") && (
                                      <div
                                        style={{
                                          paddingTop: 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        <button
                                          className="btn btn-md btn-primary"
                                          onClick={() => showHideDiv(item.id)}
                                        >
                                          Video List
                                        </button>
                                        <div
                                          style={{
                                            textAlign: "center",
                                            display:
                                              item.id === activeVideoListId
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          <table className="table">
                                            <tr>
                                              <th>Name</th>
                                              <th>Video</th>
                                            </tr>
                                            {Object.entries(newVideoData).map(
                                              ([key, value]) => {
                                                return (
                                                  <tr>
                                                    <td
                                                      style={{ width: "60%" }}
                                                    >
                                                      {value.video_name}
                                                    </td>
                                                    <td
                                                      style={{ width: "40%" }}
                                                    >
                                                      <button
                                                        className="btn btn-md "
                                                        style={{
                                                          backgroundColor:
                                                            "#ff6600",
                                                          border:
                                                            "1px solid red",
                                                          height: "45px",
                                                          color: "white",
                                                        }}
                                                        onClick={() =>
                                                          openVideoModalNew(
                                                            value.practice_name,
                                                            value.video_id ||
                                                              value.video_id,
                                                            "NOHD"
                                                          )
                                                        }
                                                      >
                                                        Watch Video
                                                      </button>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </table>
                                        </div>
                                      </div>
                                    )}

                                  {type === "result2" && (
                                    <Link
                                      to={`review/${item.practice_id}/${item.attempt_number}`}
                                    >
                                      <button className="btn btn-md viewResultBtn">
                                        View Result
                                      </button>
                                    </Link>
                                  )}

                                  {type === "test" &&
                                    user_type === "PAID" &&
                                    item.attempts_count <= 2 && (
                                      <Link
                                        to={`test/${item.id}/${
                                          item.attempts_count + 1
                                        }`}
                                        target="_blank"
                                      >
                                        <button className="btn btn-md startTestBtn">
                                          Start Test (Attempt -{" "}
                                          {item.attempts_count + 1})
                                        </button>
                                      </Link>
                                    )}

                                  {type === "test" &&
                                    user_type === "UNPAID" &&
                                    item.attempts_count <= 2 &&
                                    item.view_status === "PAID" && (
                                      <Link
                                        to={`test/${item.id}/${
                                          item.attempts_count + 1
                                        }`}
                                        target="_blank"
                                      >
                                      <button
                                        className="btn btn-md startTestBtn"
                                      >
                                        Start Test (Attempt - )
                                      </button>
                                      </Link>
                                    )}

                                  {type === "test" &&
                                    user_type === "UNPAID" &&
                                    item.attempts_count <= 2 &&
                                    item.view_status === "FREE" && (
                                      <Link
                                        to={`test/${item.id}/${
                                          item.attempts_count + 1
                                        }`}
                                        target="_blank"
                                      >
                                        <button className="btn btn-md startTestBtn">
                                          Start Test (Attempt - )
                                        </button>
                                      </Link>
                                    )}

                                  {type === "test" &&
                                    item.attempts_count >= 3 && (
                                      <button
                                        disabled
                                        className="btn btn-md btn-danger"
                                      >
                                        Maximum Attempt Limit Over
                                      </button>
                                    )}
                                </td>

                                {type !== "video" && type !== "result2" && (
                                  <td>{item.attempts_count}</td>
                                )}
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    </Accordion.Body>
                  </div>
                </Accordion.Item>
              );
            })}
          </Accordion>
        )}
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      {type != "review" && getMyObject()}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalData.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video controls style={{ width: "100%" }}>
            <source src={modalData.url} type="video/mp4"></source>
            <source src={modalData.url} type="video/ogg"></source>
          </video>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default withRouter(AccordianContainerNew);

{
  /* <div className={activeId === '1' ? 'panel-wrap active-panel' : 'panel-wrap'}>
<div className="panel-header">
    <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
        <div className="accordian-head-c">
            <div>
                2  hello
            </div>
            <div>
                {activeId != 1 && <Plus />}
                {activeId == 1 && <Dash />}
            </div>
        </div>
    </Accordion.Toggle>
</div>

<Accordion.Collapse eventKey="1">
    <div className="panel-body">
        <table style={{ width: '98%', margin: 'auto' }}>
            <tr>
                <th>Sr. No.</th>
                <th>Practise</th>
                <th>Tutorial Documentary</th>
            </tr>
            <tr>
                <td>1</td>
                <td>Word Problems Warmup 1</td>
                <td><button>Watch Video</button></td>
            </tr>
        </table>
    </div>
</Accordion.Collapse>
</div> */
}
