import React, { useEffect, useState, useContext } from "react";
import CheckAnswer from "../../Components/CheckAnswer/CheckAnswer";
import "./TestNew.css";
import { Link, useParams, withRouter } from "react-router-dom";
import StopWatch from "../../Components/StopWatch/StopWatch";
import { apiCallToken } from "../../Utils/Network.js";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";
import { convertToMilliseconds } from "../../Utils/Utils";
import AccordianContainer from "../../Components/AccordianContainer/AccordianContainer";

function TestNew(props) {
  let { id, countId } = useParams();
  const {
    set_changeQuestion,
    setCurrentCount,
    selectedAnswer,
    setSelectedAnswer,
    currentCount,
    answerArray,
    setAnswerArray,
  } = useContext(UserCredsContext);
  const [count, setCount] = useState(1);
  const [questionArray, setQuestionArray] = useState([]);
  const [questionOptions, setQuestionOptions] = useState([]);
  const [optionsArray, setOptionsArray] = useState([]);
  const [arrayList, setArrayList] = useState([]);
  const [storeId, setStoreId] = useState([]);
  const [selectedAnswerArray, setSelectedAnswerArray] = useState([]);
  const [answeredList, setAnsweredList] = useState([]);
  const [accordianData, setAccordianData] = useState({});
  /*const [accordianData, setAccordianData] = useState({
    Video: [
      {
        id: "203",
        practice_name: "Warmup Geometry",
        attempts_count: 0,
      },
    ],
  });*/
  const [accordianTitle, setAccordianTitle] = useState("Demo");
  const counter = (type, _count) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (type === "prev") {
      setCount((prevCount) => prevCount - 1);
      set_changeQuestion(type, true);
      setCurrentCount(_count - 1);
    } else if (type === "next") {
      postAnswer(type, _count);
      if (questionArray.length !== currentCount) {
        setCurrentCount(_count + 1);
      }
    }
  };

  // useEffect(() => {
  //     set_changeQuestion('current_count', count)
  // }, [count])

  useEffect(() => {
    if (count <= questionArray.length) {
      if (questionArray.length !== 0 && questionArray[count - 1].options != 0) {
        setQuestionOptions(questionArray[count - 1].options);
        setOptionsCheck(
          questionArray,
          questionArray[count - 1].options,
          count - 1
        );
      }
    }
  }, [count]);

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    // /student/questions/practise/id/44/1
    apiCallToken
      .get(`/student/questions/practise/id/${id}/${countId}`)
      .then((response) => {
        // console.log(response.data.data.question_list, "response for test");
        const { user_answer_list, question_list } = response.data.data;
        // console.log(question_list, "question_list");
        var answer_list = [];
        var questionLists = question_list;
        for (var i = 0; i <= question_list.length - 1; i++) {
          let insert_obj = question_list[i].options;
          for (var j = 0; j <= question_list[i].options.length - 1; j++) {
            insert_obj[j].checked = false;
          }
          answer_list.push(insert_obj);
        }
        setQuestionArray(response.data.data.question_list);
        if (response.data.data.length !== 0) {
          var myOptions = response.data.data.question_list[0].options;
          var insertedOptions = myOptions.map((obj) => ({
            ...obj,
            checked: false,
          }));
          setQuestionOptions(insertedOptions);
          setOptionsCheck(response.data.data.question_list, insertedOptions, 0);
          var ans_arr = [];
          let n = 0;
          while (n < user_answer_list.length) {
            n++;
          }
          // setAnsweredList(response.data.data.user_answer_list);
          // setCount(response.data.data.user_answer_list.length + 1);
        }

        if (response.data.data.video_list.length !== 0) {
          var video_practice_list = {
            Video: response.data.data.video_list,
          };
          // console.log(video_practice_list, "NEW code");
          setAccordianData(video_practice_list);
        }
      })
      .catch((error) => {
        console.log(error, "error fof video");
      });
  };

  const postAnswer = (type, _count) => {
    // if (selectedAnswer == null) {
    //   setCount((prevCount) => prevCount + 1);
    //   set_changeQuestion(type, true);
    //   setSelectedAnswer(null);
    //   return;
    // }

    var answerIs = [];
    var result;
    for (var i = 0; i <= optionsArray.length - 1; i++) {
      result = optionsArray[i].filter((obj) => {
        return obj.checked === true;
      });
      if (result.length) {
        answerIs.push(result[0].option_mapping);
      }
      // setSelectedAnswerArray(answerIs);
    }
    answerIs.sort((a, b) => parseFloat(a) - parseFloat(b));
    answerIs = answerIs.map(Number);
    // console.log(answerIs, "answerIs");
    if (!answerIs.length) {
      setCount((prevCount) => prevCount + 1);
      set_changeQuestion(type, true);
      setSelectedAnswer(null);
      return;
    }

    var retreiveTime = JSON.parse(localStorage.getItem("time"));
    var total_time = convertToMilliseconds(retreiveTime);
    var integerQuestionId = parseInt(questionArray[count - 1].id);
    var integerAnswerId = parseInt(selectedAnswer);

    const data = {
      practice_id: id,
      question_id: integerQuestionId,
      ans_mapp_id: answerIs,
      time_taken: total_time,
      attempt_number: countId,
    };
    apiCallToken
      .post(`/student/answer/submit`, data)
      .then((response) => {
        setCount((prevCount) => prevCount + 1);
        set_changeQuestion(type, true);
        setSelectedAnswer(null);
        setSelectedAnswerArray([]);
        if (currentCount >= questionArray.length) {
          submitFinalAnswer();
        }
      })
      .catch((error) => {
        console.log(error, "error fof video");
      });
  };
  const submitFinalAnswer = () => {
    // /review
    const data = {
      practice_id: id,
      attempt_number: countId,
    };
    localStorage.setItem("practice_id", id);
    localStorage.setItem("attempt_number", countId);
    apiCallToken
      .post(`/student/final/submit`, data)
      .then((response) => {
        var final_data = response.data;
        var test = response.data.data.question_ans_list;
        var hello = test.filter(
          (v, i, a) => a.findIndex((t) => t.q_id === v.q_id) === i
        );
        var js = hello.sort(
          (a, b) => parseFloat(a.price) - parseFloat(b.price)
        );
        final_data.data.question_ans_list = js;
        const newData = JSON.stringify(final_data);
        // console.log(newData, "data in local");
        localStorage.setItem("final_data", newData);
        props.history.push(`/review/${id}/${countId}`);
      })
      .catch((error) => {
        console.log(error, "error fof video");
        alert("Something went wrong");
      });
  };

  // console.log(questionOptions, selectedAnswer, "questionOptions");

  const setActiveList = (position, id, _map_id, idx) => {
    var myArray;
    setSelectedAnswer(_map_id);
    var result = optionsArray[idx].map((el) => {
      var myStoreId = [0, 2];
      myStoreId.splice(idx, 0, id);
      setStoreId(myStoreId);
      return el.id == id ? { ...el, checked: true } : { ...el, checked: false };
    });
    var new_array = [...optionsArray];
    new_array[idx] = result;
    setOptionsArray(new_array);
    var concat_array = [];
    optionsArray.forEach((element) => {
      concat_array.push(...element);
    });
    // console.log(concat_array, "concat_arrayconcat_array");
    setArrayList(concat_array);
  };

  const setOptionsCheck = (questionArray, optionUOList, question_count) => {
    // console.log(
    //   questionArray,
    //   optionUOList,
    //   question_count,
    //   "questionArray, optionList, question_count"
    // );
    optionUOList.sort(
      (a, b) => parseFloat(a.option_mapping) - parseFloat(b.option_mapping)
    );
    var optionList = optionUOList;

    var questionList = questionArray[question_count];
    if (questionList && optionList.length !== 0) {
      var option_count = parseInt(questionList.no_of_options_set);
      var option_array = questionList.no_of_options.split(",");

      var arrayOfNumbers = option_array.map(Number);
      var added = 0;
      var arrays = [];
      arrayOfNumbers.forEach((element, index) => {
        var p1 = optionList.slice(added, added + element);
        added = added + element;
        arrays = [...arrays, p1];
      });
      setOptionsArray(arrays);
    }
  };
  // console.log(
  //   questionArray,
  //   currentCount,
  //   selectedAnswerArray,
  //   "option checked"
  // );
  return (
    <div style={{ marginTop: "150px", marginBottom: "200px" }}>
      <div
        className="accordThis"
        style={{
          width: "80%",
          margin: "0px auto 30px",
          border: "1px solid #f34325",
          backgroundColor: "#f34325",
          borderRadius: "5px"
        }}
      >
        {/* <AccordianContainer
          _data={accordianData}
          _title={accordianTitle}
          type="video"
        /> */}
      </div>

      <div className="qa-c-l01">
        <div className="question-totalnum-c">
          <p>
            {" "}
            {count} out of {questionArray.length}
          </p>
        </div>
        <div className="timer-float-cla">
          <StopWatch questionCount={count} />
        </div>
        {questionArray.length && count <= questionArray.length && (
          <p>
            Q.{count}){" "}
            <span
              dangerouslySetInnerHTML={{
                __html: `${questionArray[count - 1].question_desc}`,
              }}
            ></span>
          </p>
        )}

        {/* <CheckAnswer questionCount={count} displayOptions={questionOptions} _item={questionArray[count-1]}/> */}
        <div className="checklist-answer-c">
          {optionsArray != undefined &&
            optionsArray.length != 0 &&
            optionsArray.map((item, idx) => {
              // console.log(item, "item sandesh");
              return (
                <div>
                  {item.map((newItem, newIdx) => {
                    return (
                      <label
                        className="container container-fc-if hide-in"
                        style={{
                          padding: "0px 0px 0px 40px",
                          marginBottom: "10px",
                        }}
                        onClick={() =>
                          setActiveList(
                            "first",
                            newItem.id,
                            newItem.option_mapping,
                            idx
                          )
                        }
                      >
                        <span
                          className="optionsBx"
                          dangerouslySetInnerHTML={{
                            __html: `${newItem.options_val}`,
                          }}
                        ></span>
                        <input type="checkbox" checked={newItem.checked} />
                        <span class="checkmark"></span>
                      </label>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
      <div className="button-container-c">
        {count !== 1 && (
          <button
            className="btn btn-md btn-primary"
            onClick={() => counter("prev", count)}
          >
            Previous
          </button>
        )}
        &nbsp;
        {count !== questionArray.length && (
          <button
            className="btn btn-md btn-primary"
            onClick={() => counter("next", count)}
          >
            Next
          </button>
        )}
        {count === questionArray.length && (
          <button
            className="btn btn-md btn-primary"
            onClick={() => counter("next", count)}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
}

export default withRouter(TestNew);
