import React, { useEffect } from 'react';
import './Training.css';
import './display.css';
import greImg from '../../Assets/GRES.png';
import { Link } from 'react-router-dom';
import ReactReadMoreReadLess from "react-read-more-read-less";
import * as Icon from 'react-icons/fa';
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const longText =
  "Various universities and colleges around the world use varied methods and criteria to judge and grade the performance of a student. These marking systems vary even among the universities in one country, thus making it difficult to place all the students entering one level from heterogeneous education systems. Entrance exams help bring all the students at par to understand the student’s capability in Mathematical and Verbal Reasoning. ";


function Gre() {
    useEffect(() => {
        AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
    }, []);
    return (
        <div>
             <section className="sectionBannerGre u-align-center u-clearfix u-image u-shading u-section-1" src data-image-width={1280} data-image-height={800} id="sec-5147">
          <div className="u-align-center u-clearfix u-sheet u-valign-middle u-sheet-1">
            
          </div>
        </section>
        <section className="u-align-center u-clearfix u-image u-section-2" id="carousel_cf0d" data-image-width={1980} data-image-height={2214}>
          <div className="u-clearfix u-sheet u-sheet-1">
            <h2 className="u-text u-text-1">GRE</h2>
            <p className="u-text txtJust">Encompassing Verbal and Quantitative Reasoning along with Analytical Writing, the Graduate Record Examination, designed and administered by the ETS, is a standardized test harnessed to assess a student’s readiness for graduate school. Valid for 5 years, the score constitutes an imperative criterion in the admission process of many postgraduate and doctorate programs, including specializations in MBA and law.</p>
            <p></p>
            <h4 className="u-text">The Imperial Advantage</h4>
            <div className="u-expanded-width u-list u-list-1">
              <div className="u-repeater u-repeater-1">
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-1">
                    <h2 className="u-custom-font u-font-montserrat u-text u-text-default u-text-palette-3-base u-text-3">1</h2>
                    <p className="u-align-justify u-text u-text-grey-50 u-text-4"><h4 className="u-text advTag">Seasoned Faculty</h4>{'\n'}The Faculty of any educational institution forms its backbone. Our faculty is highly knowledgeable in the various aspects of GRE and can be depended upon to expertly pilot students in all the subjects that form a part of this examination. Our faculty members have enough experience of such exams and training. Their student-friendly nature and easy approachability mean that the students never need to hesitate to clear any doubt that inevitably emerges during the classes and afterwards.</p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-2">
                    <h2 className="u-custom-font u-font-montserrat u-text u-text-default u-text-palette-3-base u-text-5">2</h2>
                    <p className="u-align-justify u-text u-text-grey-50 u-text-4"><h4 className="u-text advTag">Study Material</h4>{'\n'}Imperial Overseas provides the students with comprehensive study materials that have been tailored by experts with the sole intention of making them concise for the students while being highly useful for them in attaining high GRE scores. Our study materials ensure that a student does not have to consult a basketful of different books which can lead to confusion.</p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-3">
                    <h2 className="u-custom-font u-font-montserrat u-text u-text-default u-text-palette-3-base u-text-7">3<br />
                    </h2>
                    <p className="u-align-justify u-text u-text-grey-50 u-text-4"><h4 className="u-text advTag">Infrastructure</h4>{'\n'}Our infrastructure includes every facility that forms a professional educational institute. We have well-equipped spacious classrooms with various facilities for the students.</p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-4">
                    <h2 className="u-custom-font u-font-montserrat u-text u-text-default u-text-palette-3-base u-text-9">4<br />
                    </h2>
                    <p className="u-align-justify u-text u-text-grey-50 u-text-4"><h4 className="u-text advTag">Periodic and Pertinent Testing</h4>{'\n'}The students must be consistently tested in the same format of examination as GRE before they are ready to take the main examination. To this end, we conduct over 10 GRE mock tests and a free diagnostic test initially. The aim is to make the students sentient of their strengths and weaknesses so that they are able to improve themselves gradually and finally perform well in the main examination.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
           <section className="u-clearfix u-section-3" id="sec-e06d">
        <div className="u-clearfix u-sheet u-sheet-1">
          <h3 className="u-text u-text-default u-text-1">The students choosing Imperial Overseas for GRE coaching can rest assured to get the best training for the following reasons:</h3>
          <div className="u-list u-list-1">
            <div className="u-repeater u-repeater-1">
              <div className="u-container-style u-list-item u-repeater-item">
                <div className="u-container-layout u-similar-container u-valign-top u-container-layout-1"><span className="u-icon u-icon-circle u-text-custom-color-1 u-icon-1">
                   <FontAwesomeIcon icon={faCheckCircle} size="2x" />
                   </span>
                  <p className="u-text u-text-2">Our faculty does not just confine itself to teaching all the subjects of GRE but also conducts integrated doubt-solving sessions for the students. These sessions provide ample opportunities for students to find answers to all queries and doubts that may have been encountered in any subject.</p>
                </div>
              </div>
              <div className="u-container-style u-list-item u-repeater-item">
                <div className="u-container-layout u-similar-container u-valign-top u-container-layout-2"><span className="u-icon u-icon-circle u-text-custom-color-1 u-icon-2">
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" /></span>
                  <p className="u-text u-text-3">Questions are provided for practice at both home and class. The questions are all of GRE-exam difficulty level which helps the students in becoming familiar with the kind of questions that are expected in the main examination. Regular practice makes students more confident in solving all kinds of problems and getting a good GRE score.</p>
                </div>
              </div>
              <div className="u-container-style u-list-item u-repeater-item">
                <div className="u-container-layout u-similar-container u-valign-top u-container-layout-3"><span className="u-icon u-icon-circle u-text-custom-color-1 u-icon-3">
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" /></span>
                  <p className="u-text u-text-4">Periodic timed tests ensure that the students are able to assess their status at regular intervals. We also conduct full-length mock tests at the end of the classes for the same purpose.</p>
                </div>
              </div>
              <div className="u-container-style u-list-item u-repeater-item">
                <div className="u-container-layout u-similar-container u-valign-top u-container-layout-4"><span className="u-icon u-icon-circle u-text-custom-color-1 u-icon-4">
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" /></span>
                  <p className="u-text u-text-6">Each and every student is provided the benefit of a one-on-one analysis of his or her performance in the full-length tests. This analysis makes him or her aware of the various areas which need urgent correction for better performance. Needless to say that identifying the weak areas and working on them ensure better results.</p>
                </div>
              </div>
              <div className="u-container-style u-list-item u-repeater-item">
                <div className="u-container-layout u-similar-container u-valign-top u-container-layout-5"><span className="u-icon u-icon-circle u-text-custom-color-1 u-icon-5">
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" /></span>
                  <p className="u-text u-text-6">We have various tips and tricks at our disposal for fast, accurate, and efficient performance on the GRE. All such tips and tricks are taught effectively to the students for their benefit.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        <section className="u-align-center u-clearfix u-grey-5 u-section-4" id="carousel_d273">
          <div className="u-clearfix u-sheet u-sheet-1">
            <div className="u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-list u-list-1">
              <div className="u-repeater u-repeater-1">
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-1">
                    <h2 className="u-text u-text-default u-text-palette-3-base u-text-1">1</h2>
                    <p className="u-text u-text-grey-40 u-text-2" style={{ margin: '-31px 0 0 34px' }}>
                        12  Live classes of Quant and Verbal Each of 3 hours
                    </p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-2">
                    <h2 className="u-text u-text-default u-text-palette-3-base u-text-3">2</h2>
                    <p className="u-text u-text-grey-40 u-text-4" style={{     margin: '-38px 0 0 35px' }}>Weekly Topic Tests :
                        Pre – recorded Classwork Lectures for Self-Paced Learning
                    </p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-3">
                    <h2 className="u-text u-text-default u-text-palette-3-base u-text-5">3<br />
                    </h2>
                    <p className="u-text u-text-grey-40 u-text-6" style={{ margin: '-30px 0 0 33px' }}>Over 5000+ Question Bank of various Difficulty</p></div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-4">
                    <h2 className="u-text u-text-default u-text-palette-3-base u-text-7">4<br />
                    </h2>
                    <p className="u-text u-text-grey-40 u-text-8">Regular Doubt solving and Weekly Vocab building with our powerful and easy technique</p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-5">
                    <h2 className="u-text u-text-default u-text-palette-3-base u-text-9">5<br />
                    </h2>
                    <p className="u-text u-text-grey-40 u-text-10">
                    Pictographic, Etymology and mnemonics  memory techniques to improve Vocabulary</p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-6">
                    <h2 className="u-text u-text-default u-text-palette-3-base u-text-11">6<br />
                    </h2>
                    <p className="u-text u-text-grey-40 u-text-12">
                    Quant Strategies and Tricks to crack questions in less than 1 minute and Mind maps for Learning</p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-6">
                    <h2 className="u-text u-text-default u-text-palette-3-base u-text-11">7<br />
                    </h2>
                    <p className="u-text u-text-grey-40 u-text-14" style={{ margin: '-34px 34px 0'  }}>
                    Exam Criteria Focused Learning and Accelerated Learning Techniques</p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-6">
                    <h2 className="u-text u-text-default u-text-palette-3-base u-text-11">8<br />
                    </h2>
                    <p className="u-text u-text-grey-40 u-text-16" style={{ margin: '-34px 34px 0'  }}>
                    10 Full Length Test Papers to time your Test </p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-6">
                    <h2 className="u-text u-text-default u-text-palette-3-base u-text-11">9<br />
                    </h2>
                    <p className="u-text u-text-grey-40 u-text-18" style={{ margin: '-34px 34px 0'  }}>
                    Free diagnostic tests are available every week and Option to attend A Mock Session (Details Of Our Next Seminar/Mock Class)</p>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="u-align-center u-container-style u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-group u-shape-rectangle u-white u-group-1">
              <div className="u-container-layout u-valign-top u-container-layout-7">
                
                <img
                    src={greImg}
                    className="img-fluid"
                    alt=""
                    className="u-image u-image-default u-image-1"
                    data-image-width={715} data-image-height={700}
                />
                <h2 className="u-text u-text-default u-text-13">Avail a Costless Demo now!</h2>
                <p className="u-text u-text-default-lg u-text-default-xl u-text-14"> Encompassing Verbal and Quantitative Reasoning along with Analytical Writing, the Graduate Record Examination, designed and administered by the ETS, is a standardized test harnessed to assess a student’s readiness for graduate school.</p>
                <a href="/contact" className="u-active-palette-1-base u-border-none u-btn u-btn-round u-button-style u-color-scheme-summer-time u-color-style-multicolor-1 u-hover-palette-1-base u-palette-3-base u-radius-11 u-text-active-white u-text-body-alt-color u-text-hover-white u-btn-1">
                  Contact Us&nbsp;&nbsp;
                  <span className="u-icon u-icon-1">
                    <svg className="u-svg-content" viewBox="0 0 268.832 268.832" x="0px" y="0px" style={{width: '1em', height: '1em'}}>
                      <g>
                        <path d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,17.678l58.661,58.661H12.5   c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,58.661c-4.882,4.882-4.882,12.796,0,17.678   c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z" />
                      </g>
                    </svg>
                    <img />
                  </span>
                </a>
                
              </div>
            </div>
          </div>
        </section>
        </div>
    )
}

export default Gre;