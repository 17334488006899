import React, { useEffect, useState } from "react";
import { apiCallToken } from "../../Utils/Network.js";
import AccordianContainer from "../../Components/AccordianContainer/AccordianContainer";
import AccordianContainerNew from "../../Components/AccordianContainerNew/AccordianContainerNew";

import { video_list } from "../../Utils/ApiUrls";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext.js";
function Question() {
  const { user_details } = React.useContext(UserCredsContext);
  const [navData, setNavData] = useState([]);
  const [videoList, setVideoList] = useState(null);
  const [topicList, setTopicList] = useState(null);
  const [tabSelected, setTabSelected] = useState(0);
  const [isActive, setIsActive] = useState(0);
  const [accordianTitle, setAccordianTitle] = useState("");
  const [questionCountId, setQuestionCountId] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  // console.log(accordianTitle, "accordianTitle");
  const getData = () => {
    apiCallToken(`/student/practise/combinedata`)
      .then((response) => {
        // console.log(response.data.data.attempts_count, "response for question");
        // setQuestionCountId(response.data.data.attempts_count);
        setNavData(response.data.data);
        // setReviewList(response.data.data.attempts_count);
        var first_key = response.data.data[Object.keys(response.data.data)[0]];
        var second_key = first_key[Object.keys(first_key)[0]];
        // console.log(second_key, "second_key");
        // setAccordianData(second_key);
        setTopicList(second_key["TOPIC-LIST"]);
        setVideoList(second_key["VIDEO-LIST"]);
      })
      .catch((error) => {
        console.log(error, "error fof video");
      });
  };

  const checkClick = (idx, tabIdx, _data) => {
    setTabSelected(tabIdx);
    setIsActive(idx);
    setTopicList(_data["TOPIC-LIST"]);
    setVideoList(_data["VIDEO-LIST"]);
    // var first_key = Object.keys(navData)[tabSelected];
    // var second_key = Object.keys(navData[first_key])[idx];
    // setAccordianTitle(second_key);
  };
  // console.log(questionCountId, "questionCountId");
  return (
    <div className="video-container-pc" style={{ marginTop: "100px" }}>
      <div className="video-container-pclf">
        <div className="video-sub-menu">
          <div className="left-nav-c">
            {Object.entries(navData).map(([key, value], idx) => {
              
              var active_list = tabSelected === idx ? true : false;
              return (
                <ul
                  className="outer-ul-li-c"
                  style={{ listStyleType: "none" }}
                  key={idx}
                // onClick={() => checkClick(idx, "selected", value)}
                >
                  <li style={{ background: "#F0F0F0" }}>
                    <p
                      className="ul-li-header-c"
                      style={{ marginBottom: "0px" }}
                    >
                      {key}
                    </p>
                    {Object.entries(value).map(([key, value], idxi) => {
                      const selectable = user_details.paid_status === "PAID" || user_details.paid_status === "UNPAID" ||
                        value["VIEW-STATUS"] === "FREE";
                      return (
                        <ul
                          className={`${active_list && idxi === isActive
                              ? "inner-ul-li-c-active"
                              : "inner-ul-li-c"
                            }`}
                          onClick={() =>
                            selectable ? checkClick(idxi, idx, value) : null
                          }
                          style={{
                            listStyleType: "none",
                            padding: "10px 5px 10px 10px",
                            marginTop: "0px",
                            marginBottom: "0px",
                            cursor: selectable ? "pointer" : "not-allowed",
                            opacity: selectable ? 1 : 0.5,
                          }}
                          key={idxi}
                        >
                          <li>{key}</li>
                        </ul>
                      );
                    })}
                  </li>
                </ul>
              );
            })}
          </div>
        </div>
        <div className="video-list-item">
          {topicList && (
            <div className="right-nav-details">
              <p className="nav-details-h">
                <b>Topic List</b>
              </p>
              <AccordianContainerNew
                _data={topicList}
                _title={accordianTitle}
                type="test"
                user_type={user_details.paid_status}
                q_id={questionCountId}
              />
            </div>
          )}
          {videoList && (
            <div className="right-nav-details">
              <p className="nav-details-h">
                <b>Video List</b>
              </p>
              <AccordianContainerNew
                _data={videoList}
                _title={accordianTitle}
                type="video"
                user_type={user_details.paid_status}
                q_id={questionCountId}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Question;
