import React, { useEffect } from 'react';
import './Training.css';
import './display.css';
import greImg from '../../Assets/TOEFLS.png';
import { Link } from 'react-router-dom';
import ReactReadMoreReadLess from "react-read-more-read-less";
import * as Icon from 'react-icons/fa';
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const longText =
  "Various universities and colleges around the world use varied methods and criteria to judge and grade the performance of a student. These marking systems vary even among the universities in one country, thus making it difficult to place all the students entering one level from heterogeneous education systems. Entrance exams help bring all the students at par to understand the student’s capability in Mathematical and Verbal Reasoning. ";


function Tofel() {
    useEffect(() => {
        AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
    }, []);
    return (
        <div>
             <section className="sectionBannerToefl u-align-center u-clearfix u-image u-shading u-section-1" src data-image-width={1280} data-image-height={800} id="sec-5147">
          <div className="u-align-center u-clearfix u-sheet u-valign-middle u-sheet-1">
           </div>
        </section>
        <section className="u-align-center u-clearfix u-image u-section-2" id="carousel_cf0d" data-image-width={1980} data-image-height={2214}>
          <div className="u-clearfix u-sheet u-sheet-1">
            <h2 className="u-text u-text-1">TOEFL</h2>
            <p className="u-text txtJust">TOEFL (The Test of English as a Foreign Language) is a standardized exam used to assess the English proficiency of non-native English speakers. It is accepted by over 10,000 universities in more than 150 countries. It is held all year round with around 50 test dates. While the exam cost varies geographically, in India, it costs USD 170. TOEFL is administered online from where it gets its name – TOEFL iBT (Internet Based Test). The test is of four and a half hours duration and consists of a variety of reading, listening, speaking and writing tasks.</p>
            <p className="txtJust">TOEFL measures the ability of non-native speakers of English to use and understand English as it is spoken, written, and heard in college and university settings. The TOEFL tests all four language skills that are important for effective communication: Speaking, Listening, Reading, and Writing. The test helps students demonstrate that they have the English skills needed for success. TOEFL also emphasizes integrated skills and provides better information to institutions about students' ability to communicate in an academic setting and their readiness for academic coursework.</p>
            <p className="txtJust">TOEFL will be needed for Education in the USA, UK, Canada, and New Zealand. The TOEFL test is developed and administered by the US-based "Educational Testing Service" (ETS). This implies that ETS sets the questions, conducts the test, and sends each examinee the score report. For the conduct of the test, ETS has appointed Testing Agencies in various countries, which act as franchisees for ETS. In India, this agency is the "Prometric Testing Services Private Limited" which administers the test at various centers across India.</p>
            <p className="txtJust">The TOEFL test is held all-round-the-year. Unlike other exams, you can choose your own date and time for taking the TOEFL test. September to December is the peak season for the TOEFL test, so in case you intend to take the test during this period, you need to register very early (say 90 days in advance) to get a date of your choice. Otherwise, registering at least three days in advance is mandatory. The test lasts roughly four hours. Further details are available on <Link to='https://www.ets.org/toefl'>www.toefl.org</Link>. You can register for the test through the same site.</p>
            <p></p>
            <h4 className="u-text">The Imperial Advantage</h4>
            <div className="u-expanded-width u-list u-list-1">
              <div className="u-repeater u-repeater-1">
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-1">
                    <h2 className="u-custom-font u-font-montserrat u-text u-text-default u-text-palette-3-base u-text-3">1</h2>
                    <p className="u-align-justify u-text u-text-grey-50 u-text-4"><h4 className="u-text advTag">Content</h4>{'\n'}We use the most relevant and frequently tested content helping our students to fully acclimate themselves with the TOEFL exam and to organise their time in an effective manner to achieve the best scores.</p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-2">
                    <h2 className="u-custom-font u-font-montserrat u-text u-text-default u-text-palette-3-base u-text-5">2</h2>
                    <p className="u-align-justify u-text u-text-grey-50 u-text-4"><h4 className="u-text advTag">Critical Thinking Strategies</h4>{'\n'}We constantly update and customise our study and reference material, along with practice test papers, to ensure that it is addressing students’ specific areas of concern. A personalised feedback that identifies areas of weaknesses and strengths is rendered regularly to help the student identify his or her performance patterns and work in a focused manner. Our result-oriented strategies enable students to increase both speed and accuracy.</p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-3">
                    <h2 className="u-custom-font u-font-montserrat u-text u-text-default u-text-palette-3-base u-text-7">3<br />
                    </h2>
                    <p className="u-align-justify u-text u-text-grey-50 u-text-4"><h4 className="u-text advTag">Essential Practice</h4>{'\n'}Our collection of practice tests follows the same structure as the actual TOEFL exam. The realistic practice and extensive training, along with test day tips and exam strategies provided by expert instructors, enhance students’ confidence in preparation for the actual test.</p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-4">
                    <h2 className="u-custom-font u-font-montserrat u-text u-text-default u-text-palette-3-base u-text-9">4<br />
                    </h2>
                    <p className="u-align-justify u-text u-text-grey-50 u-text-4"><h4 className="u-text advTag">No Time Constraint</h4>{'\n'} We provide intensive classroom coaching with access to our study material and other relevant resources. Classes are held with the provision of maximum flexibility in the schedule.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
           <section className="u-clearfix u-section-3" id="sec-e06d">
        <div className="u-clearfix u-sheet u-sheet-1">
          <h3 className="u-text u-text-default u-text-1">The students choosing Imperial Overseas for TOEFL coaching can rest assured to get the best training for the following reasons:</h3>
          <div className="u-list u-list-1">
            <div className="u-repeater u-repeater-1">
              <div className="u-container-style u-list-item u-repeater-item">
                <div className="u-container-layout u-similar-container u-valign-top u-container-layout-1"><span className="u-icon u-icon-circle u-text-custom-color-1 u-icon-1">
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" /></span>
                  <p className="u-text u-text-2">Our faculty does not just confine itself to teaching all the subjects of TOEFL but also conducts integrated doubt-solving sessions for the students. These sessions provide ample opportunities for students to find answers to all queries and doubts that may have been encountered in any subject.</p>
                </div>
              </div>
              <div className="u-container-style u-list-item u-repeater-item">
                <div className="u-container-layout u-similar-container u-valign-top u-container-layout-2"><span className="u-icon u-icon-circle u-text-custom-color-1 u-icon-2">
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" /></span>
                  <p className="u-text u-text-3">Questions are provided for practice at both home and class. The questions are all of TOEFL-exam difficulty level which helps the students in becoming familiar with the kind of questions that are expected in the main examination. Regular practice makes students more confident in solving all kinds of problems and getting a good TOEFL score.</p>
                </div>
              </div>
              <div className="u-container-style u-list-item u-repeater-item">
                <div className="u-container-layout u-similar-container u-valign-top u-container-layout-3"><span className="u-icon u-icon-circle u-text-custom-color-1 u-icon-3">
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" /></span>
                  <p className="u-text u-text-4">Periodic timed tests ensure that the students are able to assess their status at regular intervals. We also conduct full-length mock tests at the end of the classes for the same purpose.</p>
                </div>
              </div>
              <div className="u-container-style u-list-item u-repeater-item">
                <div className="u-container-layout u-similar-container u-valign-top u-container-layout-4"><span className="u-icon u-icon-circle u-text-custom-color-1 u-icon-4">
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" /></span>
                  <p className="u-text u-text-6">Each and every student is provided the benefit of a one-on-one analysis of his or her performance in the full-length tests. This analysis makes him or her aware of the various areas which need urgent correction for better performance. Needless to say that identifying the weak areas and working on them ensure better results.</p>
                </div>
              </div>
              <div className="u-container-style u-list-item u-repeater-item">
                <div className="u-container-layout u-similar-container u-valign-top u-container-layout-5"><span className="u-icon u-icon-circle u-text-custom-color-1 u-icon-5">
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" /></span>
                  <p className="u-text u-text-6">We have various tips and tricks at our disposal for fast, accurate, and efficient performance on the TOEFL. All such tips and tricks are taught effectively to the students for their benefit.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        <section className="u-align-center u-clearfix u-grey-5 u-section-4" id="carousel_d273">
          <div className="u-clearfix u-sheet u-sheet-1">
            <div className="u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-list u-list-1">
              <div className="u-repeater u-repeater-1">
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-1">
                    <h2 className="u-text u-text-default u-text-palette-3-base u-text-1">1</h2>
                    <p className="u-text u-text-grey-40 u-text-2" style={{ margin: '-30px 0 0 35px' }}>
                        23 days of quality classroom training 
                    </p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-2">
                    <h2 className="u-text u-text-default u-text-palette-3-base u-text-3">2</h2>
                    <p className="u-text u-text-grey-40 u-text-4" style={{ margin: '-30px 0 0 35px' }}>
                        4 classes of each module (Reading, Listening, Writing, and Speaking)
                    </p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-3">
                    <h2 className="u-text u-text-default u-text-palette-3-base u-text-5">3<br />
                    </h2>
                    <p className="u-text u-text-grey-40 u-text-6" style={{ margin: '-30px 0 0 35px' }}>Three Full-Length Tests from the latest Cambridge books for IELTS and for TOEFL tests from TOEFL Software.</p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-4">
                    <h2 className="u-text u-text-default u-text-palette-3-base u-text-7">4<br />
                    </h2>
                    <p className="u-text u-text-grey-40 u-text-8" style={{ margin: '-30px 0 0 35px' }}>All live lectures so as to encourage doubt solving, thus no recordings.</p>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item u-shape-rectangle">
                  <div className="u-container-layout u-similar-container u-container-layout-5">
                    <h2 className="u-text u-text-default u-text-palette-3-base u-text-9">5<br />
                    </h2>
                    <p className="u-text u-text-grey-40 u-text-10" style={{ margin: '-30px 0 0 35px' }}>Strategies and tricks to crack questions and mind maps for learning. </p>
                  </div>
                </div>
               
              </div>
            </div>
            <div className="u-align-center u-container-style u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-group u-shape-rectangle u-white u-group-1">
              <div className="u-container-layout u-valign-top u-container-layout-7">
                
                <img
                    src={greImg}
                    className="img-fluid"
                    alt=""
                    className="u-image u-image-default u-image-1"
                    data-image-width={715} data-image-height={700}
                />
                <h2 className="u-text u-text-default u-text-13">Avail a Costless Demo now!</h2>
                <p className="u-text u-text-default-lg u-text-default-xl u-text-14"> TOEFL (The Test of English as a Foreign Language) is a standardized exam used to assess the English proficiency of non-native English speakers. It is accepted by over 10,000 universities in more than 150 countries.
                                        </p>
                <a href="/contact" className="u-active-palette-1-base u-border-none u-btn u-btn-round u-button-style u-color-scheme-summer-time u-color-style-multicolor-1 u-hover-palette-1-base u-palette-3-base u-radius-11 u-text-active-white u-text-body-alt-color u-text-hover-white u-btn-1">
                  Contact Us&nbsp;&nbsp;
                  <span className="u-icon u-icon-1">
                    <svg className="u-svg-content" viewBox="0 0 268.832 268.832" x="0px" y="0px" style={{width: '1em', height: '1em'}}>
                      <g>
                        <path d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,17.678l58.661,58.661H12.5   c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,58.661c-4.882,4.882-4.882,12.796,0,17.678   c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z" />
                      </g>
                    </svg>
                    <img />
                  </span>
                </a>
                
              </div>
            </div>
          </div>
        </section>
        </div>
    )
}

export default Tofel;