import React, { useEffect, useState } from "react";
import "./StartExam.css";
import { useHistory } from "react-router-dom";
import StopWatch, { StopWatch2 } from "../../Components/StopWatch/StopWatch";
import { convertToMilliseconds } from "../../Utils/Utils";
import { apiCallToken } from "../../Utils/Network";
import Loader from "../Common/loader";

function StartTest() {
  const [testInfo, setTestData] = useState([]);
  const questionArray = testInfo?.question_list;
  const [toast, setToast] = useState({ msg: "", type: "" });
  const [count, setCount] = useState(1);
  const [optionsArray, setOptionsArray] = useState([]);
  const [testNumb, setTestNum] = useState(0);
  const [answers, setAnswers] = useState({});
  const [essay, setEssay] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (toast.type) setTimeout(() => setToast({ msg: "", type: "" }), 3000);
  }, [toast]);

  useEffect(() => {
    if (questionArray?.length) steOption(count);
  }, [count, questionArray]);

  let steOption = (quesNumb) => {
    let options = questionArray[quesNumb - 1]?.options;
    let no_of_options_set = questionArray[quesNumb - 1]?.no_of_options_set;

    let temp = [];

    for (let i = 0; i < no_of_options_set; i++) {
      temp.push([]);
    }

    options.map((option) => {
      let { option_set } = option;
      temp[option_set - 1]?.push(option);
    });
    setOptionsArray(temp);
  };

  useEffect(() => {
    fetchTest(testNumb);
  }, []);

  const testEndPoint = {
    0: "/awa",
    1: "/testsetone",
    2: "/testsetthree",
    3: "/testsettwo",
    4: "/testsetfour",
  };

  const getEssay = () => {
    setLoading(true);
    apiCallToken
      .get(`/student/tests/awa`)
      .then((response) => {
        setTestData(response.data.data);
        setLoading(false);

        // console.log("awa", JSON.stringify(response));
      })
      .catch(() => {
        setLoading(false);
        setToast({ msg: "Something went wrong", type: "error" });
      });
  };

  const fetchSummury = () => {
    setLoading(true);
    apiCallToken
      .get(
        `/student/tests/finalsubmit/${testInfo.tests_id}/${testInfo.transaction_id}`
      )
      .then((response) => {
        fetchSummury2();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setToast({ msg: "Something went wrong", type: "error" });
      });
  };

  const fetchSummury2 = () => {
    history.push({ pathname: "/result", state: testInfo.transaction_id });
  };

  const fetchTest = (testNumber, isLastQues) => {
    if (isLastQues && testNumb === 4) {
      fetchSummury();
      return;
    }
    if (testNumber === 0) {
      getEssay();
    } else if (testNumber == 1) {
      setLoading(true);

      apiCallToken
        .get(
          "/student/tests" +
          testEndPoint[testNumber] +
          "/" +
          testInfo?.transaction_id
        )
        .then((res) => {
          setLoading(false);
          setTestData(res.data.data);
        })
        .catch(() => {
          setLoading(false);
          setToast({ msg: "Something went wrong", type: "error" });
        });
    } else {
      setLoading(true);

      apiCallToken
        .get(
          "/student/tests" +
          testEndPoint[testNumber] +
          "/" +
          testInfo?.tests_id +
          "/" +
          testInfo?.transaction_id
        )
        .then((res) => {
          setLoading(false);
          setTestData(res.data.data);
        })
        .catch(() => {
          setLoading(false);
          setToast({ msg: "Something went wrong", type: "error" });
        });
    }
  };

  const counter = (type, test, isTimeOut) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (type === "prev") {
      setCount((prevCount) => prevCount - 1); // prev question
    } else if (type === "next") {
      if (testInfo?.question_list?.length !== testNumb) {
        setCount((prevCount) => prevCount + 1); // next question
        submitAnswer();
      }
    } else if (type === "submit") {
      if (test === "essay") {
        submitAnswer(true, true, isTimeOut);
        fetchTest(testNumb + 1, true);
        setTestNum((prev) => prev + 1); // next test
        setCount(1);
        return;
      }
      submitAnswer(true, false, isTimeOut);
      if (testNumb <= 3) {
        fetchTest(testNumb + 1, true);
        setTestNum((prev) => prev + 1); // next test
        setCount(1);
      } else {
        fetchTest(4, true);
      }
    }
  };

  const submitAnswer = (isLastQues, isEssay, isTimeOut) => {
    let total_time;
    if (!isTimeOut) {
      if (isEssay) {
        total_time = convertToMilliseconds(
          JSON.parse(localStorage?.getItem("time"))
        );
      } else {
        total_time = convertToMilliseconds(
          JSON.parse(localStorage?.getItem("time2"))
        );
      }
    }
    let data = {
      test_id: testInfo.tests_id,
      transaction_id: testInfo?.transaction_id,
      question_id: isEssay
        ? testInfo?.awa_data?.id
        : testInfo?.question_list[count - 1]?.id,
      ans_mapp_id: isEssay
        ? [essay]
        : answers[testInfo.question_list[count - 1]?.id]?.length
          ? answers[testInfo.question_list[count - 1]?.id]
          : ["0"],
      time_taken: isTimeOut ? "" : total_time,
    };

    apiCallToken
      .post(`/student/tests/submitans`, data)
      .then((response) => {
        // console.log(JSON.stringify(response));
      })
      .catch(() => {
        setToast({ msg: "Something went wrong", type: "error" });
      });
    if (isLastQues) {
      setAnswers({});
    }
  };

  const handleAnswerSelect = (data) => {
    const { option_mapping, question_id } = data;
    // console.log("option_mapping, question_id ", option_mapping, question_id);
    let tempAns = { ...answers };
    if (
      testInfo?.question_list[count - 1]?.answer_display_type === "checkbox"
    ) {
      if (!tempAns[question_id]) {
        tempAns[question_id] = [option_mapping];
      } else {
        if (tempAns[question_id]?.includes(option_mapping)) {
          let updatedAnswer = tempAns[question_id];
          updatedAnswer.splice(tempAns[question_id].indexOf(option_mapping), 1); // removing ans if checked
          tempAns[question_id] = [...updatedAnswer];
        } else {
          tempAns[question_id] = [...tempAns[question_id], option_mapping]; // adding ans if un-checked
        }
      }
    } else {
      tempAns[question_id] = [option_mapping]; // for radio
    }
    setAnswers(tempAns);
  };

  return (
    <div style={{ marginTop: "150px", marginBottom: "200px" }}>
      {loading && <Loader />}
      <div id="snackbar" className={toast.type ? toast.type + " show" : ""}>
        {toast.msg}
      </div>

      <div className="qa-c-l01">
        <div className="question-totalnum-c">
          <h3>{testInfo?.topic_title}</h3>
        </div>
        <div
          style={{ marginBottom: 20, borderBottom: "2px solid #FBC02D" }}
          className="timer-float-cla"
        >
          {testInfo?.topic_title === "AWA ISSUE" &&
            <StopWatch
              type={false}
              questionCount={30}
              resetTime={testNumb}
              timeLimitSubmit={() =>
                counter("submit", testNumb === 0 ? "essay" : "", true)
              }
            />
          }
          {testInfo?.topic_title === "Verbal Set One" &&
            <StopWatch
              type={false}
              questionCount={18}
              resetTime={testNumb}
              timeLimitSubmit={() =>
                counter("submit", testNumb === 0 ? "essay" : "", true)
              }
            />
          }
          {testInfo?.topic_title === "Quant Set One" &&
            <StopWatch
              type={false}
              questionCount={23}
              resetTime={testNumb}
              timeLimitSubmit={() =>
                counter("submit", testNumb === 0 ? "essay" : "", true)
              }
            />
          }
          {testInfo?.topic_title === "Verbal Set Two" &&
            <StopWatch
              type={false}
              questionCount={21}
              resetTime={testNumb}
              timeLimitSubmit={() =>
                counter("submit", testNumb === 0 ? "essay" : "", true)
              }
            />
          }
          {testInfo?.topic_title === "Quant Set Two" &&
            <StopWatch
              type={false}
              questionCount={26}
              resetTime={testNumb}
              timeLimitSubmit={() =>
                counter("submit", testNumb === 0 ? "essay" : "", true)
              }
            />
          }
          <p style={{ margin: "5px 0 10px" }}>
            Time Limit:{" "}
            {testInfo?.topic_title === "AWA ISSUE" && <span style={{ fontWeight: "bold" }}>
              {30 + " "}
              minutes
            </span>
            }

            {testInfo?.topic_title === "Verbal Set One" &&
              <span style={{ fontWeight: "bold" }}>
                {18 + " "}
                minutes
              </span>
            }

            {testInfo?.topic_title === "Quant Set One" &&
              <span style={{ fontWeight: "bold" }}>
                {23 + " "}
                minutes
              </span>
            }

            {testInfo?.topic_title === "Verbal Set Two" &&
              <span style={{ fontWeight: "bold" }}>
                {21 + " "}
                minutes
              </span>
            }

            {testInfo?.topic_title === "Quant Set Two" &&
              <span style={{ fontWeight: "bold" }}>
                {26 + " "}
                minutes
              </span>
            }
          </p>
        </div>
        {testNumb === 0 ? (
          <>
            <p>
              Q.1){" "}
              <span
                dangerouslySetInnerHTML={{
                  __html: `${testInfo?.awa_data?.question_desc || ""}`,
                }}
              />
            </p>
            <textarea
              style={{ width: "100%" }}
              rows="10"
              placeholder="write here"
              value={essay}
              onChange={(e) => setEssay(e.target.value)}
            ></textarea>
          </>
        ) : (
          <>
            <div className="question-totalnum-c">
              <p>
                {" "}
                {count} out of {questionArray?.length}
              </p>
            </div>
            <div className="timer-float-cla">
              <StopWatch2 resetTime={testNumb} questionCount={count} />
            </div>
            {count <= questionArray?.length && (
              <p>
                Q.{count}){" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${questionArray[count - 1]?.question_desc || ""}`,
                  }}
                ></span>
              </p>
            )}

            {/* <CheckAnswer questionCount={count} displayOptions={questionOptions} _item={questionArray[count-1]}/> */}
            <div className="checklist-answer-c">
              {optionsArray?.length !== 0 &&
                optionsArray.map((item, idx) => {
                  return (
                    <div key={idx}>
                      {item.map((newItem, newIdx) => {
                        return (
                          <label
                            key={newIdx}
                            className="container container-fc-if hide-in"
                            style={{
                              padding: "0px 0px 0px 40px",
                              marginBottom: "10px",
                            }}
                            onChange={() => handleAnswerSelect(newItem)}
                          >
                            <span
                              className="optionsBx"
                              dangerouslySetInnerHTML={{
                                __html: `${newItem.options_val}`,
                              }}
                            ></span>
                            <input
                              type={
                                questionArray[count - 1]
                                  ?.answer_display_type === "checkbox"
                                  ? "checkbox"
                                  : "radio"
                              }
                              checked={
                                answers[newItem.question_id]?.includes(
                                  newItem?.option_mapping
                                ) ?? false
                              }
                            />
                            <span
                              className={
                                questionArray[count - 1]
                                  ?.answer_display_type === "checkbox"
                                  ? "checkmark"
                                  : "dotmark"
                              }
                            ></span>
                          </label>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </div>
      {testNumb === 0 ? (
        <div className="button-container-c">
          <button
            className="btn btn-md startExamBtn"
            onClick={() => counter("submit", "essay")}
          >
            Submit {testInfo?.topic_title}
          </button>
        </div>
      ) : (
        <div className="button-container-c">
          {count !== 1 && (
            <button
              className="btn btn-md startExamBtn"
              onClick={() => counter("prev")}
            >
              Previous
            </button>
          )}
          &nbsp;
          {count !== questionArray?.length && (
            <button
              className="btn btn-md startExamBtn"
              onClick={() => counter("next")}
            >
              Next
            </button>
          )}
          {count === questionArray?.length && (
            <button
              className="btn btn-md startExamBtn"
              onClick={() => counter("submit")}
            >
              Submit {testInfo?.topic_title}
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default StartTest;
