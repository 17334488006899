import React, { useContext, useState } from "react";
import "./navbar.css";
import { Link, withRouter } from "react-router-dom";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";
import impLogo from "../../Assets/footer/logo.png";

const Navbar = (props) => {
  const { _token } = useContext(UserCredsContext);
  const { logout } = useContext(UserCredsContext);

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  // console.log(props.location.pathname,_token,'pathname')
  return (
    <>
      <header id="header" class="header fixed-top">
        <div class="container-fluid px-0">
          <nav className="navbar">
            <div className="nav-container px-lg-5">
              <Link class="nav-link scrollto" to="">
                <a class="logo d-flex align-items-center">
                  {/* <img src={impLogo} alt="" /> */}
                  <h1>testprep.<span>live</span></h1>
                </a>
              </Link>

              <ul className={click ? "nav-menu active" : "nav-menu"}>
                {/* {_token === null && <li className="nav-item">
                                    <Link
                                        exact
                                        to="/"
                                        activeClassName="active"
                                        className="nav-links"
                                        onClick={handleClick}
                                    > Home</Link>
                                </li>} */}
                {_token !== null && (
                  <li className="nav-item">
                    <Link
                      to="/startTest"
                      activeClassName="active"
                      className="nav-links"
                      onClick={handleClick}
                    >
                      Test
                    </Link>
                  </li>
                )}
                {_token !== null && (
                  <li className="nav-item">
                    <Link
                      exact
                      to="/dashboard"
                      activeClassName="active"
                      className="nav-links"
                      onClick={handleClick}
                    >
                      {" "}
                      Dashboard{" "}
                    </Link>
                  </li>
                )}
                {/* {_token === null && <li className="nav-item">
                                    <Link
                                        exact
                                        to="/about"
                                        activeClassName="active"
                                        className="nav-links"
                                        onClick={handleClick}
                                    >About</Link>
                                </li>} */}
                {/* {_token !== null && <li><Link  exact
                                        to="/reference"
                                        activeClassName="active"
                                        className="nav-links"
                                        onClick={handleClick}>Reference</Link>
                                        </li> */}
                {/* {_token === null && <li class="nav-item dropdown"><a href="#"><span>Training at Imperial</span> <i class="bi bi-chevron-down"></i></a>
                                    <ul>
                                        <li><Link class="nav-link scrollto" to="/gre">GRE</Link></li>
                                        <li><Link class="nav-link scrollto" to="/gmat">GMAT</Link></li>
                                        <li><Link class="nav-link scrollto" to="/ielts">IELTS</Link></li>
                                        <li><Link class="nav-link scrollto" to="/tofel">TOEFL</Link></li>
                                    </ul>
                                </li>} */}

                {_token !== null && (
                  <li className="nav-item">
                    <Link
                      to="/question"
                      activeClassName="active"
                      className="nav-links"
                      onClick={handleClick}
                    >
                      Tutorials
                    </Link>
                  </li>
                )}
                {/* {_token !== null && <li><Link to='/video'>Videos</Link></li>} */}
                {_token !== null && (
                  <li className="nav-item">
                    <Link
                      to="/testresult"
                      activeClassName="active"
                      className="nav-links"
                      onClick={handleClick}
                    >
                      {" "}
                      Review{" "}
                    </Link>
                  </li>
                )}

                {/* {_token === null && <li className="nav-item">
                                    <Link
                                        exact
                                        to="/price"
                                        activeClassName="active"
                                        className="nav-links"
                                        onClick={handleClick}
                                    > Fee Structure</Link>
                                </li>} */}
                {/* {_token === null && <li className="nav-item">
                                    <Link
                                        exact
                                        to="/contact"
                                        activeClassName="active"
                                        className="nav-links"
                                        onClick={handleClick}
                                    >Contact</Link>
                                </li>} */}
                {_token === null && (
                  <li className="nav-item">
                    <Link
                      class="getstarted scrollto"
                      to="/login"
                      onClick={handleClick}
                    >
                      SIGN IN
                    </Link>
                  </li>
                )}
                {_token === null && (
                  <li className="nav-item">
                    <Link
                      class="getstarted scrollto"
                      to="/createAccount"
                      onClick={handleClick}
                    >
                      REGISTER
                    </Link>
                  </li>
                )}

                {_token !== null && (
                  <li class="nav-item dropdown">
                    <a className="nav-links" href="#">
                      <span> Profile </span> <i class="bi bi-chevron-down"></i>
                    </a>
                    <ul>
                      <li>
                        <Link
                          class="nav-link scrollto"
                          to="/profile"
                          onClick={handleClick}
                        >
                          PROFILE
                        </Link>
                      </li>
                      <li>
                        <Link
                          class="nav-link scrollto"
                          onClick={logout}
                          to="/logout"
                        >
                          LOGOUT
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}
              </ul>
              <div className="nav-icon" onClick={handleClick}>
                {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}
                <span class="material-symbols-outlined menu">menu</span>
              </div>
            </div>
          </nav>
        </div>
      </header>
      {/* <script src="/assets/js/reactjs/react-min.js"></script>
                <script src="/assets/js/reactjs/react-dom-min.js"></script>
                <script src="/assets/js/reactjs/babel.js"></script>
                <script src="/assets/js/reactjs/axios-min.js"></script>
                <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.js"></script>
                <script src="/assets/vendor/aos/aos.js"></script>
                <script src="/assets/vendor/php-email-form/validate.js"></script>
                <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
                <script src="/assets/vendor/purecounter/purecounter.js"></script>
                <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
                <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
                <script src="/assets/js/main.js"></script> */}
    </>
  );
};

export default withRouter(Navbar);
