import React, { useState, useReducer, useContext } from "react";
import LoginPageImage from "../../Assets/Login/Characters.png";
import "./Login.css";
// import LoginPageBG from "../../Assets/Login/LoginPageBG.png";
import LoginPageBG from "../../Assets/Login/Login-bg.png";
import login from "./loginApi";
import LoginUseReducer from "./LoginUseReducer";
import { apiCall, setTokenInHeaders, setApiToken } from "../../Utils/Network";
import { login_url } from "../../Utils/ApiUrls";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";
import { Link, withRouter } from "react-router-dom";

const initialState = {
  username: "",
  password: "",
  isLoading: false,
  error: "",
  isLoggedIn: false,
};

function Login(props) {
  const { set_user_data, user_details } = useContext(UserCredsContext);
  const [state, dispatch] = useReducer(LoginUseReducer, initialState);
  const { username, password, isLoading, error, isLoggedIn } = state;
  const [toast, setToast] = useState({ msg: "", type: "" });
  const handleChange = () => { };
  const handleFormSubmit = () => { };

  React.useEffect(() => {
    if (toast.type) setTimeout(() => setToast({ msg: "", type: "" }), 3000);
  }, [toast]);

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!username || !password) {
      setToast({ msg: "Please enter login credentails !", type: "error" });
      return;
    }
    dispatch({ type: "login" });
    apiCall
      .post(login_url, { username, password })
      .then((response) => {
        dispatch({ type: "success" });
        // console.log(response, 'response')
        setToast({ msg: "Login Successfull !", type: "success" });
        const { access_token, login_object } = response.data.data;
        // console.log(access_token,'access_tokenaccess_token')
        // console.log(access_token, login_object, 'login object')
        set_user_data(login_object, access_token);
        localStorage.setItem("network_access_token", access_token);

        setTokenInHeaders(access_token);
        setApiToken(access_token);

        setTimeout(() => props.history.push("/startTest"), 1000);
      })
      .catch((error) => {
        setToast({
          msg: error.response?.data?.message || "Something went wrong !",
          type: "error",
        });
        dispatch({ type: "error" });
      });
  };

  return (
    <div
      className=" loginContainer heroLogin"
      style={{ background: `url(${LoginPageBG})` }}
    >
      <div id="snackbar" className={toast.type ? toast.type + " show" : ""}>
        {toast.msg}
      </div>
      <div className="container">
        <div className="row">
          <div
            className="col-md-5 banner-sec loginimageBox"
            style={{ backgroundImage: `url(${LoginPageImage})` }}
          ></div>
          {/* <div
            className="col-md-5 banner-sec loginimageBox"
            style={{ background: `url(${LoginPageBG})` }}
          ></div> */}
          <div className="col-md-5 login-sec loginBox">
            <h2 className="text-center">USER LOGIN</h2>

            <form id="login" className="validate" onSubmit={(e) => onSubmit(e)}>
              <div className="input-box form-group">
                <input
                  type="text"
                  placeholder="Email Id"
                  name="username"
                  className="form-control loginInput change"
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      fieldName: "username",
                      payload: e.currentTarget.value,
                    })
                  }
                  value={username}
                />

                {/* <small className="help-block">{usernameError}</small> */}
              </div>
              <div className="input-box form-group ">
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  className="form-control loginInput change"
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      fieldName: "password",
                      payload: e.currentTarget.value,
                    })
                  }
                  value={password}
                />
                {/* <small className="help-block">{passwordError}</small> */}
              </div>
              <div className="check-slide">
                <label className="label_check" for="checkbox-01">
                  <input id="checkbox-01" type="checkbox" defaultChecked />{" "}
                  Remember Me
                </label>
                <div className="right-link">
                  {/* <a href="lost-password.php">Lost Password? </a> */}
                  <Link className="float-right" to="/createAccount">
                    New here? Register
                  </Link>
                </div>
              </div>
              <div className="submit-slide textAlignCenter">
                <input
                  type="submit"
                  value="Login"
                  className="loginButton1"
                  onClick={handleFormSubmit}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

// try {
//     const apiData = login({ username, password });
//     console.log(apiData, 'value sakhare')
//     apiData.then((response) => {
//         dispatch({ type: 'success' });
//         props.history.push("/dashboard");
//         console.log(response, 'value sandesh')
//     },
//         (error) => {
//             dispatch({ type: 'error' });
//             console.log(error, 'error')
//         }
//     )
// } catch (error) {
//     alert('wrong')
//     dispatch({ type: 'error' });
// }
