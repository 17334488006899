import React, { useEffect, useState } from "react";
import "./StartTest.css";
import { useHistory } from "react-router-dom";
import { apiCallToken } from "../../Utils/Network";
import Pagination from "react-bootstrap/Pagination";
import Loader from "../Common/loader";

function StartTest(props) {
  const [giventTest, setGivenTest] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState('');
  const user_type = data?.user_details?.paid_status;

  const handleClick = () => {
    history.push("/startExam");
  };

  useEffect(() => {
    const storedData = localStorage.getItem('_data');
    if (storedData) {
        setData(JSON.parse(storedData));
    }
}, []);

  useEffect(() => {
    getAllTest();
  }, []);

  function getAllTest() {
    setLoading(true);
    apiCallToken
      .get("student/tests/giventestlist")
      .then((res) => {
        setLoading(false);
        setGivenTest(res.data.data);

        if (res.data.data?.length > 0) {
          let totalPage = Math.ceil(res?.data?.data?.length / 10);
          let temp = [];
          for (let i = 1; i <= totalPage; i++) {
            temp.push(i);
          }
          setPages([...temp]);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function viewResult(txn_id) {
    if (txn_id) {
      props.history.push({ pathname: "/result", state: txn_id });
    }
  }

  return (
    <div style={{ flex: 1 }}>
      <div className="test-container">
        <h5>Instructions</h5>

        <div className="tableDiv">
          <table>
            <thead>
              <th>Measure</th>
              <th>Question</th>
              <th>Time Limit</th>
            </thead>
            <tbody>
              <tr>
                <td>Analytical Writing (One section)</td>
                <td>1 Essay</td>
                <td>30 minutes</td>
              </tr>
              <tr>
                <td>Verbal Reasoning (Two sections)</td>
                <td>
                  <p>Section 1: 12 questions</p>
                  <p>Section 2: 15 questions</p>
                </td>
                <td>
                  <p>Section 1: 18 minutes</p>
                  <p>Section 2: 23 minutes</p>
                </td>
              </tr>
              <tr>
                <td>Quantitative Reasoning (Two sections)</td>
                <td>
                  <p>Section 1: 12 questions</p>
                  <p>Section 2: 15 questions</p>
                </td>
                <td>
                  <p>Section 1: 21 minutes</p>
                  <p>Section 2: 26 minutes</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* <button onClick={handleClick}>Start Test</button> */}

        {((user_type === "UNPAID" && giventTest.length > 2) || (user_type === "PAID" && giventTest.length > 9)) &&
          <button className="btn btn-danger" disabled>Maximum Attempt Limit Over</button>
        }

        {((user_type === "UNPAID" && giventTest.length <= 2) || (user_type === "PAID" && giventTest.length <= 9)) &&
          <button onClick={handleClick}>Start Test</button>
        }

      </div>
      <div style={{ flex: 1, marginBottom: 100 }}>
        {giventTest?.length > 0 && (
          <div
            className=" test-container panel-body"
            style={{ backgroundColor: "#fff" }}
          >
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Title
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Date
                  </th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Result
                  </th>
                </tr>
              </thead>
              <tbody>
                {giventTest
                  ?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                  ?.map((test, index) => {
                    return (
                      <>
                        <tr>
                          <th scope="row" style={{ textAlign: "center" }}>
                            Mock Test {index + 1 + (page - 1) * 10}
                          </th>
                          <td style={{ textAlign: "center" }}>
                            {test?.date_created}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <button
                              className="btn"
                              onClick={() => viewResult(test?.transaction_id)}
                            >
                              View Result
                            </button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
            <div style={{ alignSelf: "flex-end" }}>
              <Pagination>
                {pages?.map((item) => {
                  return (
                    <div style={{ margin: "0 5px" }}>
                      <Pagination.Item
                        key={item}
                        active={page === item}
                        onClick={() => setPage(item)}
                      >
                        {item}
                      </Pagination.Item>
                    </div>
                  );
                })}
              </Pagination>
            </div>
          </div>
        )}
        {loading && <Loader />}
      </div>
    </div>
  );
}

export default StartTest;
