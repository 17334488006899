export const convertToMilliseconds = ({ minutes, seconds, hours }) => {
  var minuteToMilli = minutes * 60000;
  var hourToMilli = hours * 60 * 6000;
  var secondsToMilli = seconds * 1000;
  return minuteToMilli + hourToMilli + secondsToMilli;
};

export const checkCountId = (item, list) => {
  // console.log(item, list, "list in utils");
  if (list === null || list === undefined || list.length === 0) {
    return null;
  }
  const res = list.find((x) => x.count == item.attempts_count + 1);
  return res;
};

export const insertAnswers = (question, answer) => {
  question.forEach((element, index) => {
    var matching_object = answer.find((x) => x.q_id === element.id);
    const { answer_ids, correct_option, time_taken } = matching_object;
    question[index].answer_ids = JSON.parse(answer_ids);
    question[index].correct_option = parseInt(correct_option);
    question[index].time_taken = parseInt(time_taken);
  });
  return question;
};

export const getPercentage = (partialValue, totalValue) => {
  return (100 * partialValue) / totalValue;
};

export const getTotalTime = (time) => {
  var minutes = Math.floor(time / 60);
  var seconds = Math.floor(time - minutes * 60);
  var hours = Math.floor(time / 3600);
  var time = [hours, minutes, seconds];

  return time.join(":");
};

export const splitOptions = (questionArray, optionUOList, question_count) => {
  optionUOList.sort(
    (a, b) => parseFloat(a.option_mapping) - parseFloat(b.option_mapping)
  );
  var optionList = optionUOList;

  var questionList = questionArray[question_count];
  if (questionList && optionList.length !== 0) {
    var option_count = parseInt(questionList.no_of_options_set);
    var option_array = questionList.no_of_options.split(",");
    var arrayOfNumbers = option_array.map(Number);
    var added = 0;
    var arrays = [];
    arrayOfNumbers.forEach((element, index) => {
      var p1 = optionList.slice(added, added + element);
      added = added + element;
      arrays = [...arrays, p1];
    });
    return arrays;
  }
};

export const insertAnswerObject = (question_list, answer_list) => {
  var myArray = [...question_list];
  const data = question_list.map((item, idx) => {
    myArray[idx].answer_ids = answer_list[idx].answer_ids;
    myArray[idx].correct_option = answer_list[idx].correct_option;
    // console.log(myArray, "myArray");
  });
  return myArray;
};

export const convertAnswer = (_item) => {
  var myArray = { ..._item };
  var correct_array = myArray.correct_option.split(",").map(Number);
  var answered_array = JSON.parse(myArray.answer_ids);

  if (answered_array) {
    answered_array = answered_array.map(Number);
  }
  if (answered_array) {
    answered_array = answered_array.map(Number);

    answered_array = answered_array.filter(function (cv) {
      return !correct_array.find(function (e) {
        return e == cv;
      });
    });
  }
  return { wrong_array: answered_array, correct_array: correct_array };
};

export const checkCorrectAnswer = (answered, correctAnswer) => {
  var u_answer = JSON.parse(answered);
  var answerArray = correctAnswer.split(",").map(function (item) {
    return parseInt(item, 10);
  });
  // var b = a.split(',').map(Number);
  const isEqual = (u_answer.length === answerArray.length) && (answerArray.every(val => u_answer.includes(val)));
  if(!u_answer.length){
    return 'not'
  }
  if(isEqual){
    return 'correct'
  }else if(!isEqual){
    return 'wrong'
  }
};
