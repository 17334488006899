import React, { useState, useEffect } from "react";
import "./HeroHome.css";
import bannerImg from "../../Assets/home/Banner.png";
import * as Icon from "react-bootstrap-icons";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";
import { apiCall, setApiToken, setTokenInHeaders } from "../../Utils/Network";
import { send_otp, sign_up, verify_otp } from "../../Utils/ApiUrls";

function HeroHome(props) {
  const { set_user_data } = React.useContext(UserCredsContext);
  const [data, setData] = useState({
    first_name: "",
    email: "",
    mobile_number: "",
    mobile_otp: "",
  });
  const [otpSent, setOtpSent] = useState(false);
  const [toast, setToast] = useState({ msg: "", type: "" });

  useEffect(() => {
    if (toast.type) setTimeout(() => setToast({ msg: "", type: "" }), 3000);
  }, [toast]);

  useEffect(() => {
    if (data.email && data.mobile_number.length === 10) sendOtpHandler();
  }, [data.mobile_number, data.email]);

  const sendOtpHandler = async () => {
    if (otpSent) return;
    if (parseInt(data.mobile_number) < 1000000000)
      return setToast({
        msg: "Please Enter Valid Mobile Number",
        type: "error",
      });
    setOtpSent(true);
    const payload = { mobile_number: data.mobile_number, email: data.email };
    apiCall
      .post(send_otp, payload)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setOtpSent(true);
          setToast({ msg: response.data.data, type: "success" });
        } else {
          setOtpSent(false);
          setToast({ msg: response.data.data, type: "error" });
        }
      })
      .catch((error) => {
        console.log(error);
        setToast({
          msg: error.response?.data?.message || "Something went wrong !",
          type: "error",
        });
      });
  };

  const registerHandler = async (event) => {
    event.preventDefault();
    if (!data.first_name)
      return setToast({ msg: "Please Enter Name", type: "error" });
    else if (!data.email)
      return setToast({ msg: "Please Enter Email", type: "error" });
    else if (!data.mobile_number)
      return setToast({ msg: "Please Enter Mobile Number", type: "error" });
    else if (!data.mobile_otp)
      return setToast({ msg: "Please Enter OTP", type: "error" });

    const payload = {
      mobile_number: data.mobile_number,
      mobile_otp: data.mobile_otp,
      email: data.email,
    };
    apiCall
      .post(verify_otp, payload)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          apiCall
            .post(sign_up, data)
            .then((response) => {
              console.log(response);
              if (response.status === 200) {
                setToast({ msg: response.data.data, type: "success" });
                const { access_token, login_object } = response.data.data;
                set_user_data(login_object, access_token);
                localStorage.setItem("network_access_token", access_token);
                setTokenInHeaders(access_token);
                setApiToken(access_token);
                setTimeout(() => props.history.push("/dashboard"), 1000);
              } else {
                setOtpSent(false);
                setToast({ msg: response.data.data, type: "error" });
              }
            })
            .catch((error) => {
              console.log(error);
              setOtpSent(false);
              setToast({
                msg: error.response?.data?.message || "Something went wrong !",
                type: "error",
              });
            });
        } else {
          setToast({ msg: response.data.data, type: "error" });
          setOtpSent(false);
        }
      })
      .catch((error) => {
        setOtpSent(false);
        setToast({
          msg: error.response?.data?.message || "Something went wrong !",
          type: "error",
        });
      });
  };

  return (
    <div>
      <section
        id="hero"
        className="marginLeftSection hero d-flex align-items-center bgImage"
        style={{ background: `url(${bannerImg})` }}
      >
        <div id="snackbar" className={toast.type ? toast.type + " show" : ""}>
          {toast.msg}
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6"></div>
            {/* <div
              className="col-xl-5 col-lg-5 col-md-5 heroDiv"
              data-aos="zoom-out"
              data-aos-delay="200"
            > */}
            <div
              className="col-lg-5 col-md-5 col-width heroDiv marginTop"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <h1 data-aos="fade-up">Avail a Costless Demo now!</h1>
              <div data-aos="fade-up" data-aos-delay="600">
                <form
                  action="forms/contact.php"
                  method="post"
                  className="php-email-form"
                >
                  <div className="col-md-12">
                    <div className="col-md-12">
                      <input
                        type="text"
                        name="name"
                        className="form-control herotextBox registerFormControl"
                        placeholder="Name"
                        required
                        value={data.first_name}
                        onChange={(e) =>
                          setData({ ...data, first_name: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="email"
                        className="form-control herotextBox registerFormControl"
                        name="email"
                        placeholder="Email Id"
                        required
                        value={data.email}
                        onChange={(e) =>
                          setData({ ...data, email: e.target.value })
                        }
                      />
                    </div>
	  	   <div className="col-md-12">
                      <input
                        type="number"
                        className="form-control herotextBox registerFormControl"
                        name="mobile"
                        placeholder="Phone Number"
                        required
                        maxLength={10}
                        value={data.mobile_number}
                        onChange={(e) =>
                          setData({ ...data, mobile_number: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form-control herotextBox registerFormControl"
                        name="city"
                        placeholder="OTP"
                        required
                        value={data.mobile_otp}
                        onChange={(e) =>
                          setData({ ...data, mobile_otp: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </form>
                <div className="text-center text-lg-center">
                  <div
                    onClick={registerHandler}
                    className="btn-get-started btn text-white scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Get Started</span>
                    <Icon.ArrowRight className="ms-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HeroHome;
