import React, { useState, useEffect } from 'react';
import HeroHome from '../../Components/HeroHome/HeroHome';
import AOS from "aos";
import "aos/dist/aos.css";
import greImg from '../../Assets/home/GRE.png';
import GMAT from '../../Assets/home/GMAT.png';
import IELTS from '../../Assets/home/IELTS.png';
import TOEFL from '../../Assets/home/TOEFL.png';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import './HomePage.css';
import AlternateSections from '../../Container/Homepage/AlternateSection';

function HomePage() {
    useEffect(() => {
        AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        AOS.refresh();
    }, []);
    return (
        <div>
            <HeroHome />
            <section id="values" className="values">
                <Container>
                    <div data-aos="fade-up">
                        <header className="section-header section-margin">
                            <p>Our Cardinal Value lies in Valuing, Requiting, and Fulfilling the Hallowed Trust and Faith our Students bestow us with! </p>
                            <h2>We make their historic journeys as scintillatingly sublime as their dearest destinations!</h2>                       
                        </header>
                        <Container>
                            <Row>
                                <Col lg={3} md={3}>
                                    <Card>
                                      <Card.Img variant="top" src={greImg} />
                                      <Card.Body>
                                        
                                        <Card.Text>
                                          Encompassing Verbal and Quantitative Reasoning along with Analytical Writing, the Graduate Record Examination, designed and administered by the ETS, is a standardized test harnessed to assess a student’s readiness for graduate school.
                                        </Card.Text>
                                        
                                      </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={3} md={3}>
                                    <Card >
                                      <Card.Img variant="top" src={GMAT} />
                                      <Card.Body>
                                        
                                        <Card.Text>
                                          A pivotal part of the business school admission process, the Graduate Management AdmissionTest is a multiple-choice, computer-based, and computer-adaptive standardized exam that is often required for admission to graduate business programs (MBA) globally.
                                        </Card.Text>
                                        
                                      </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={3} md={3}>
                                    <Card >
                                      <Card.Img variant="top" src={IELTS} />
                                      <Card.Body>
                                        
                                        <Card.Text>
                                          IELTS has been devised to assist you to work, study or migrate to a country where English is the native language, for example Australia, Canada, New Zealand, the UK and USA.
                                        </Card.Text>
                                        
                                      </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={3} md={3}>
                                    <Card >
                                      <Card.Img variant="top" src={TOEFL} />
                                      <Card.Body>
                                        
                                        <Card.Text>
                                          TOEFL (The Test of English as a Foreign Language) is a standardized exam used to assess the English proficiency of non-native English speakers. It is accepted by over 10,000 universities in more than 150 countries.
                                        </Card.Text>
                                        
                                      </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </section>
            <AlternateSections />
        </div>
    )
}

export default HomePage;