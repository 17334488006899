import React, {useState, useEffect} from 'react';
import { apiCallToken } from "../../Utils/Network.js";
import AccordianContainer from "../../Components/AccordianContainer/AccordianContainer";
import { video_list } from "../../Utils/ApiUrls";
function Scholarship() {
  const [navData, setNavData] = useState([]);
  const [accordianData, setAccordianData] = useState();
  const [tabSelected, setTabSelected] = useState(0);
  const [isActive, setIsActive] = useState(0);
  const [accordianTitle, setAccordianTitle] = useState("");
  const [questionCountId, setQuestionCountId] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  // console.log(accordianTitle,'accordianTitle')
  const getData = () => {
    apiCallToken(`/student/practise/data`)
      .then((response) => {

        // console.log(response.data.data.attempts_count, "response for question");
        setQuestionCountId(response.data.data.attempts_count);
        setNavData(response.data.data.list);
        var first_key =
          response.data.data.list[Object.keys(response.data.data)[0]];
        var second_key = first_key[Object.keys(first_key)[0]];
        // console.log(second_key,'second_key')
        setAccordianData(second_key);

      })
      .catch((error) => {
        console.log(error, "error fof video");
      });
  };

  const checkClick = (idx, type, _data) => {
    if (type === "selected") {
      setTabSelected(idx);
    } else if (type === "active") {
      setIsActive(idx);
      setAccordianData(_data);
      var first_key = Object.keys(navData)[tabSelected];
      var second_key = Object.keys(navData[first_key])[idx];
      setAccordianTitle(second_key);
    }
  };
  // console.log(questionCountId, "questionCountId");
  return (
    <div className="video-container-pc" style={{ marginTop: "100px" }}>
      <div className="video-container-pclf">
        <div className="video-sub-menu">
          <div className="left-nav-c">
            {Object.entries(navData).map(([key, value], idx) => {
              var active_list = tabSelected === idx ? true : false;
              return (
                <ul
                  className="outer-ul-li-c"
                  style={{ listStyleType: "none" }}
                  key={idx}
                  onClick={() => checkClick(idx, "selected", value)}
                >
                  <li style={{ background: "#F0F0F0" }}>
                    <p className="ul-li-header-c">{key}</p>
                    {Object.entries(value).map(([key, value], idxi) => {
                      return (
                        <ul
                          className={`${
                            active_list && idxi === isActive
                              ? "inner-ul-li-c-active"
                              : "inner-ul-li-c"
                          }`}
                          onClick={() => checkClick(idxi, "active", value)}
                          style={{ listStyleType: "none" }}
                          key={idxi}
                        >
                          <li>{key}</li>
                        </ul>
                      );
                    })}
                  </li>
                </ul>
              );
            })}
          </div>
        </div>
        <div className="video-list-item">
          <div className="right-nav-details">
            <p className="nav-details-h">
              <b>Verbal</b>
            </p>
            <AccordianContainer
              _data={accordianData}
              _title={accordianTitle}
              type="test"
              q_id={questionCountId}
            />
          </div>
        </div>
      </div>
    </div>
  );
}


export default Scholarship;